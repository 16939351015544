import React from "react";
import Topbar from "./global/Topbar";
import { Box, Typography } from "@mui/material";

const Overview = () => {
  const OverviewList = [
    {
      ForStudents:
        "Participate to transform the future of data-backed healthcare. Get mentored to advance towards the epicenter of healthcare revolution. ",
      ForExperienced:
        "Participate to transform the future of data-backed healthcare. Get mentored to advance towards the epicenter of healthcare revolution. ",
    },

    {
      ForStudents: "Gain internship and job opportunities for students. ",
      ForExperienced:
        "Get mentored to advance and know the opportunities present in Solutionec to enhance your career. ",
    },
  ];

  const renderHeader = () => {
    let headerElement = ["For Students", "For Working Professionals"];
    return headerElement.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };

  const renderBody = () => {
    return (
      OverviewList &&
      OverviewList.map(({ ForStudents, ForExperienced }) => {
        return (
          <tr key={ForStudents}>
            <td>{ForStudents}</td>
            <td>{ForExperienced}</td>
          </tr>
        );
      })
    );
  };
  return (
    <div
      style={{ backgroundColor: "rgba(245,247,247,255)", marginLeft: "1rem",marginRight:'1rem' }}
    >
      <p className="main-title">Overview</p>

      <p className="details-text">
        Are you interested in reshaping the future of healthcare?
      </p>

      <p className="details-text">
        This hackathon is designed to inspire and draw attention to the need for
        new thinking and technology in the healthcare industry.
      </p>

      <Box marginTop={5}>
        <table id="prizeMain">
          <thead>
            <tr>{renderHeader()}</tr>
          </thead>
          <tbody>{renderBody()}</tbody>
        </table>
      </Box>
      <p className="main-title">Theme</p>

      <p className="sub-title">
        Revolutionizing Healthcare, Veterinary, Environment, &amp; Child Life
        Experience through:
      </p>

      <p className="sub-title" style={{ marginLeft: "50px" }}>
        1. The Power of AI &amp; Machine Learning
      </p>

      <p className="sub-title" style={{ marginLeft: "50px" }}>
        2. Data Advancement &amp; Digital service
      </p>

      <p className="main-title">About Solutionec</p>

      <p className="details-text">
        Solutionec &ndash; Solution Excellence Center &ndash; is a Paris-based
        business intelligence and analytics consulting firm that delivers
        ambitious and innovative solutions for the leading players in the
        healthcare and life sciences industry worldwide. We provide a unique
        blend of data savvy, industry expertise and technological excellence
        that allows global healthcare companies to improve their core operations
        and launch new business models.
      </p>

      <p className="details-text">
      To visit our website -  <a className="sub-title"  style={{marginLeft:"1px",color:"#052a58"}}href="https://www.solutionec.com/"target="_blank">
      Click here
              </a>{" "} 
      </p>
    </div>
  );
};

export default Overview;
