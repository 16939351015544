import "./App.css";
import SideBar from "./components/SideBar";
import Topbar from "./scenes/global/Topbar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./scenes/About";
import Overview from "./scenes/Overview";
import Themes from "./scenes/Themes";
import TimeLine from "./scenes/TimeLine";
import { FacebookShareButton, FacebookIcon } from "react-share";
import { TwitterShareButton, TwitterIcon } from "react-share";
import { LinkedinShareButton, LinkedinIcon } from "react-share";

import { AiFillLinkedin } from "react-icons/ai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import Rules from "./scenes/Rules";
import Prizes from "./scenes/Prizes";
import FAQs from "./scenes/FAQs";
import Eligibility from "./scenes/Eligibility";

import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { Button } from "react-bootstrap";
import { Padding } from "@mui/icons-material";
function App() {
  const [isOpen, setIsOpen] = useState(true);
  const [open, setOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const handleShare = () => {
    const shareUrl = `https://www.instagram.com/solutionec_stories/?url=${encodeURIComponent(
      `https://www.instagram.com/solutionec_stories/`
    )}`;
    window.open(shareUrl, "_blank");
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <div style={{ backgroundColor: "rgba(245,247,247,255)" }}>
      <Topbar open={open}
          setOpen={setOpen}
          handleClickOpen={handleClickOpen} ></Topbar>
      <div
        style={{
          display: "grid",
          paddingTop: "10px",
          flexDirection: "row",
        }}
      >
        <SideBar
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          toggle={toggle}
        ></SideBar>
        <div className="sideBarBanner">
          <div style={{ backgroundColor: "rgba(245,247,247,255)" }}>
            <Routes>
              <Route path="/" element={<Overview toggle={toggle} />} />
              <Route path="/Themes" element={<Themes toggle={toggle} />} />
              <Route path="/Overview" element={<Overview toggle={toggle} />} />
              <Route path="/Prizes" element={<Prizes toggle={toggle} />} />
              <Route path="/FAQs" element={<FAQs toggle={toggle} />} />
              <Route path="/TimeLine" element={<TimeLine toggle={toggle} />} />
              <Route
                path="/Eligibility"
                element={<Eligibility toggle={toggle} />}
              />
              <Route path="/Rules" element={<Rules toggle={toggle} />} />
              <Route path="*" element={<> not found</>} />
            </Routes>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "10px",
            }}
            className="social"
          >
            <p
              className="main-title"
              style={{ marginTop: "30px", marginLeft: "0px" }}
            >
              Social Follow
            </p>
            <div
              style={{ display: "flex", marginTop: "10px" }}
            >
                <a
                href="https://www.linkedin.com/company/solutionec"
                target="_blank"
                className="imagecss"
              >
                <img src={`linkedin1.png`} alt={"manoj"} loading="lazy" />
              </a>
              <a
                className="imagecss"
                target="_blank"
                href="https://www.instagram.com/solutionec_stories/"
              >
                <img src={`instagram.png`} alt={"manoj"} loading="lazy" />
              </a>
              <a
                href="https://www.youtube.com/@Solutionec"
                target="_blank"
                className="imagecss"
              >
                <img src={`youtube.png`} alt={"manoj"} loading="lazy" />
              </a>
              <a href="https://twitter.com/Solutionec1" target="_blank" className="imagecss">
                <img src={`twitter1.png`} alt={"manoj"} loading="lazy" />
              </a>
            
            
            </div>
            <div className="bannerimg">
              <img
                style={{ width: "100%", marginTop: "10px",cursor:"pointer" }}
                src={`MicrosoftTeams-image (6).png`}
                alt={"banner"}
                onClick={handleClickOpen}
                loading="lazy"
              />
            </div>
            <div>
              <p className="main-title" style={{ marginLeft: "0px" }}>
                Social Share
              </p>
              <div
                style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}
              >
                <LinkedinShareButton
                className="imagecss"
                  url={"https://breakthrough.solutionec.com/"}
                  title="BREAKTHROUGH 2023 -  a Solutionec Hackathon"
                >
                <img src={`linkedin1.png`} alt={"manoj"} loading="lazy" />
                </LinkedinShareButton>
                <TwitterShareButton
                className="imagecss"
                  url={"https://breakthrough.solutionec.com/"}
                  title="BREAKTHROUGH 2023 -  a Solutionec Hackathon"
                  hashtag="#muo"
                  
                >
                <img src={`twitter1.png`} alt={"manoj"}  loading="lazy" />
                </TwitterShareButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
