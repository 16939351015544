import {
  Box,
  Typography,
  Card,
  CardActions,
  CardContent,
  Grid,
} from "@mui/material";
import React from "react";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";

const Themes = () => {
  const [expanded, setExpanded] = React.useState(null);

  const handleExpandClick = (value) => {
    setExpanded(value);
  };

  return (
    <div style={{ marginBottom: "50px", marginLeft: "1rem",marginRight:'1rem' }}>
      {/* <Typography
        color="black"
        fontSize={19}
        fontWeight={600}
        style={{
          marginLeft: "30px",
          marginTop: "30px",
          verticalAlign: "middle",
        }}
      >
        Theme
      </Typography> */}
      <p className="main-title">Theme</p>

      <p className="sub-title">
        Revolutionizing Healthcare, Veterinary, Environment, &amp; Child Life
        Experience through:
      </p>

      <p className="sub-title" style={{ marginLeft: "50px" }}>
        1. The Power of AI &amp; Machine Learning
      </p>

      <p className="sub-title" style={{ marginLeft: "50px" }}>
        2. Data Advancement &amp; Digital service
      </p>

      <p></p>

      <p className="details-texttop" style={{ marginTop: "20px" }}>
      Around 50% of the world’s population still lacks the full coverage of essential health services. More than 700 million people struggle to provide basic healthcare facilities for themselves and their families resulting in a whopping 100 millions of them into debt and poverty.
      </p>

      <p></p>

      <p className="details-text">
        Advancements in artificial intelligence (AI) and machine learning (ML)
        have the potential to revolutionize healthcare by enhancing diagnostic
        accuracy, optimizing treatment plans, and improving patient outcomes.
        However, there are still several challenges and opportunities that need
        to be addressed to fully harness the power of AI and ML in healthcare.
      </p>

      <p></p>

      <p className="details-text">
      While there has been a considerable progress in integrating informatics with human medicine in practice and education, veterinary medicine lags in both the areas. However, the latter offers an immense potential for transactional research. Veterinary informatics combines the knowledge and techniques currently in use within human medical field.
      </p>

      <p></p>

      <p className="details-text">
      Working on a better, healthier life for the coming generations is always a work-in-progress for the preceding ones. There exists plethora of long-term conditions among children around the world including Asthma, Mental Health issues, Cancer, and Congenital conditions. While we cannot meet all the ends at once, enabling simplified processes and experiences is of high significance. 
      </p>

      <ul className="main-title">Knack can be Hacked with:</ul>

      <p></p>

      <ul className="main-titletheams">
        {" "}
        <li>Detailed problem analysis and solution design.</li>
      </ul>

      <ul className="main-titletheams">
        {" "}
        <li>Prototyped AI/ML model or system implementation.</li>
      </ul>

      <ul className="main-titletheams">
        {" "}
        <li>Evaluation metrics and performance analysis.</li>
      </ul>

      <ul className="main-titletheams">
        {" "}
        <li>
          Demonstration of the solution's effectiveness through real or
          simulated data.
        </li>
      </ul>

      <ul className="main-titletheams">
        {" "}
        <li>
          Documentation summarizing the solution approach, challenges faced, and
          future scalability.
        </li>
      </ul>

      <ul className="main-titletheams">
        {" "}
        <li>
          Presentation outlining the solution and its potential impact on
          healthcare.
        </li>
      </ul>

      <ul className="main-titletheams">
        {" "}
        <li>
          Reliable answers to clinical questions:&#8239;Assess the strength of a
          recommendation given and the quality of the evidence supporting it to
          help you make informed clinical decisions.
        </li>
      </ul>

      <ul className="main-titletheams">
        {" "}
        <li>
          Facilitate remote patient care and the secure exchange of health
          information including patient symptoms, test results, reports, scans,
          etc., among healthcare providers and patients.
        </li>
      </ul>

      <ul className="main-titletheams">
        {" "}
        <li>
          Shift care to decentralized care (primary care centre, mobile centres,
          home setting, city hospitals, etc.)
        </li>
      </ul>

      <ul className="main-titletheams">
        {" "}
        <li>
          Simplifying routine tasks of healthcare workers by upskilling through
          training and education
        </li>
      </ul>
      <p></p>

      <p></p>

      <p></p>

      <p></p>

      <p></p>

      <p></p>

      <p></p>

      <p></p>

      <p></p>

      <p></p>

      <p></p>

      <p></p>

      <p></p>

      <p></p>

      <p></p>

      <p className="main-title">Focus Areas:</p>

      <p></p>

      <ul className="main-titletheams">
        {" "}
        <li>Early disease detection and diagnosis of diseases</li>
      </ul>

      <p></p>

      <ul className="main-titletheams">
        {" "}
        <li>Personalized treatment plans enhancing treatment and health </li>
      </ul>

      <ul className="main-titletheams">
        {" "}
        <li>Enhancing medical imaging and diagnosis </li>
      </ul>

      <ul className="main-titletheams">
        {" "}
        <li>Improve patient monitoring and remote care </li>
      </ul>

      <ul className="main-titletheams">
        {" "}
        <li>Integrate patient and practice data onto a single platform </li>
      </ul>

      <ul className="main-titletheams">
        {" "}
        <li>Improve the quality of veterinary care </li>
      </ul>

      <ul className="main-titletheams">
        {" "}
        <li>Solutions for Children in long-term Care</li>
      </ul>

      {/* <Grid item > 
          <Card gap={10} variant="outlined" 
           >
          <CardContent   sx={{padding:"0px"}} />

          <CardActions style={{justifyContent:"space-between",background:"#5f73b5"}} disable>
          <p className="main-titles">1. ML/AI in Healthcare Industry</p>
            <IconButton
              onClick={() => handleExpandClick(1)}
              aria-label="show more"
            >
              <ExpandMoreIcon style={{color:"white"}}></ExpandMoreIcon>
            </IconButton>
          </CardActions>
          <Collapse in={expanded === 1} timeout="auto" unmountOnExit>
            <CardContent style={{justifyContent:"space-between",background:"#5f73b5"}} sx={{padding:"0px"}}>
        
        <p className="details-texttops">
          Advancements in artificial intelligence (AI) and machine learning (ML)
          have the potential to revolutionize healthcare by enhancing diagnostic
          accuracy, optimizing treatment plans, and improving patient outcomes.
          However, there are still several challenges and opportunities that
          need to be addressed to fully harness the power of AI and ML in
          healthcare.
        </p>

        <p className="sub-titles">Problem statements:</p>

        <p className="details-texts">
          <span class="bolded">Early Disease Detection and Diagnosis -</span>{" "}
          Develop AI-based algorithms or systems that can accurately and
          efficiently detect diseases at an early stage, enabling proactive
          intervention and timely treatment. This could involve developing
          models for early detection of conditions such as cancer,
          cardiovascular diseases, or neurodegenerative disorders.
        </p>

        <p className="details-texts">
          <span class="bolded">Personalized Treatment Plans -</span> Create
          AI-powered frameworks that leverage patient-specific data (genomic



          information, medical history, lifestyle factors, etc.) to generate
          personalized treatment plans. The goal is to optimize treatment
          outcomes, minimize adverse effects, and enhance patient satisfaction
          by tailoring interventions based on individual characteristics.
        </p>

        <p className="details-texts">
          <span class="bolded">Medical Imaging and Diagnosis -</span> Develop a
          solution/algorithm that enhance the accuracy and efficiency of medical
          imaging analysis for diagnosis. This could include techniques such as
          image processing, image recognition, semantic segmentation, or anomaly
          detection to assist radiologists in detecting abnormalities,
          classifying diseases, and reducing interpretation errors.
        </p>

        <p className="details-texts">
          <span class="bolded">Patient Monitoring and Remote Care -</span>{" "}
          Design an AI-driven system that enable remote patient monitoring,
          early deterioration detection, and proactive intervention. This could
          involve developing wearable devices, sensor networks, or intelligent
          algorithms that continuously analyze patient data and provide
          real-time feedback to healthcare providers for timely intervention.
        </p>

        <p className="details-texts">
          Participants can select specific use cases within the broad domain of
          AI and ML in healthcare and propose comprehensive solution(s)
          addressing a chosen problem. The emphasis should be on practical
          applicability, feasibility, and potential impact on improving
          healthcare outcomes.
        </p>

        <p className="details-texts">Things to think about:</p>

        <ul className="bullet-pointss">
          {" "}
          <li> Detailed problem analysis and solution design.</li>
        </ul>

        <ul className="bullet-pointss">
          {" "}
          <li>Prototyped AI/ML model or system implementation.</li>
        </ul>

        <ul className="bullet-pointss">
          {" "}
          <li> Evaluation metrics and performance analysis.</li>
        </ul>

        <ul className="bullet-pointss">
          {" "}
          <li>
            Demonstration of the solution's effectiveness through real or
            simulated data.
          </li>
        </ul>

        <ul className="bullet-pointss">
          {" "}
          <li>
            {" "}
            Documentation summarizing the solution approach, challenges faced,
            and future scalability.
          </li>
        </ul>

        <ul className="bullet-pointss">
          {" "}
          <li>
            {" "}
            Presentation outlining the solution and its potential impact on
            healthcare.
          </li>
        </ul>
    
            </CardContent>
          </Collapse>
        </Card>   
          </Grid>
          <Grid item > 
          <Card gap={10} variant="outlined" 
           >
          <CardContent   sx={{padding:"0px"}} />
          <CardActions style={{justifyContent:"space-between",background:"#5f73b5"}} disable>
          <p className="main-titles">2. Access to care</p>
            <IconButton
              onClick={() => handleExpandClick(2)}
              aria-label="show more"
            >
              <ExpandMoreIcon style={{color:"white"}}></ExpandMoreIcon>
            </IconButton>
          </CardActions>
          <Collapse in={expanded===2} timeout="auto" unmountOnExit>
            <CardContent style={{justifyContent:"space-between",background:"#5f73b5"}} sx={{padding:"0px"}}>
        
            <ul className="bullet-pointss">
          {" "}
          <li> Digitally enabled services</li>
        </ul>

        <ul className="bullet-pointss">
          {" "}
          <li> Ease of access to healthcare</li>
        </ul>

        <p className="details-texts">
          Up to 50% of the world&rsquo;s people still lack full coverage of
          essential health services. And 800 million people spend at least 10%
          of their household budgets on health expenses for themselves, a sick
          child or other family member, sending 100 million of those people into
          extreme poverty.
        </p>

        <p className="details-texts">
          &ldquo;Our aim is to enable access to affordable and quality
          healthcare for everyone, everywhere&rdquo;.
        </p>

        <p className="details-texts">Things to think about:</p>

        <ul className="bullet-pointss">
          {" "}
          <li>
            {" "}
            Reliable answers to clinical questions:&#8239;Assess the strength of
            a recommendation given and the quality of the evidence supporting it
            to help you make informed clinical decisions.
          </li>
        </ul>

        <ul className="bullet-pointss">
          {" "}
          <li>
            {" "}
            Drug information:&#8239;Explore adult and paediatric drug topics,
            individual medication monographs, and drug-to-drug interactions
            tools through our included drug reference Lexicomp&reg;, which has
            been used in quality improvement programs in healthcare, including
            quality improvement in nursing.
          </li>
        </ul>

        <ul className="bullet-pointss">
          {" "}
          <li>
            {" "}
            Portability: Access reliable content when and where you need it with
            hands-free, voice-enabled search within our mobile app to get you
            the answers you need quickly and easily.
          </li>
        </ul>

        <ul className="bullet-pointss">
          {" "}
          <li>
            {" "}
            Enable more underserved communities to access different medical
            tests to enable screening, early diagnosis, and therapy for
            non-communicable conditions.
          </li>
        </ul>

        <ul className="bullet-pointss">
          {" "}
          <li>
            Facilitate remote patient care and the secure exchange of health
            information including patient symptoms, test results, reports, scans
            etc., among healthcare providers and patients.
          </li>
        </ul>

        <ul className="bullet-pointss">
          {" "}
          <li>
            {" "}
            Shift care to decentralized care (primary care centre, mobile
            centres, home setting, city hospitals etc.)
          </li>
        </ul>

        <ul className="bullet-pointss">
          {" "}
          <li>
            {" "}
            Simplifying routine tasks of healthcare workers by upskilling
            through training and education
          </li>
        </ul>
            </CardContent>
          </Collapse>
        </Card>
        
          </Grid>
          <Grid item > 
          <Card gap={10} variant="outlined" 
           >
          <CardContent   sx={{padding:"0px"}} />

          <CardActions style={{justifyContent:"space-between",background:"#5f73b5"}} disable>
          <p className="main-titles">3. Data Advancement in Veterinary practice</p>
            <IconButton
              onClick={() => handleExpandClick(3)}
              aria-label="show more"
            >
              <ExpandMoreIcon style={{color:"white"}}></ExpandMoreIcon>
            </IconButton>
          </CardActions>
          <Collapse in={expanded===3} timeout="auto" unmountOnExit>
            <CardContent style={{justifyContent:"space-between",background:"#5f73b5"}} sx={{padding:"0px"}}>
            <p className="details-textTops">
          While there has been significant progress integrating informatics with
          human medicine both in practice and education, veterinary medicine
          lags in both areas. However, informatics in veterinary medicine offers
          significant possibilities for translational research.
        </p>

        <p className="details-texts">
          Use of biomedical informatics in veterinary medicine, what we term
          veterinary informatics, combines the knowledge and techniques
          currently utilized within the human medical field of biomedical
          informatics with veterinary domain knowledge.
        </p>

        <p className="details-texts">Things to think about:</p>

        <ul className="bullet-pointss">
          {" "}
          <li>
            {" "}
            INCREASE PATIENT COMMUNICATION OPPORTUNITIES: Improving
            communication between the veterinary practice and patient base
            provides numerous opportunities for repeat visits. Companion animal
            offices can benefit from sending appointment reminders for vaccines,
            seasonal parasite control and grooming appointments, either by
            postal mail or electronic communication
          </li>
        </ul>

        <ul className="bullet-pointss">
          {" "}
          <li>
            {" "}
            IMPROVE QUALITY OF CARE: As technological improvements continue to
            advance the level of care veterinarians are able to provide,
            affordability and accessibility have allowed more widespread
            integration into smaller practices. Diagnostic lab equipment has
            become easier to acquire and maintain in-house, offering more
            immediate results that can seamlessly integrate into electronic
            patient charts.
          </li>
        </ul>

        <ul className="bullet-pointss">
          {" "}
          <li>
            {" "}
            INTEGRATE PATIENT AND PRACTICE DATA ONTO A SINGLE PLATFORM: By
            integrating scheduling, charting, blood work, testing, billing and
            patient reminders into a single platform, veterinary practices can
            realize significant time and cost savings, increase productivity,
            and improve patient care. For smaller practices with fewer
            resources, outsourcing certain components such as client
            communications, payment and collections, data security, inventory
            management and staff training can reap tremendous benefits.
          </li>
        </ul>
   
            </CardContent>
          </Collapse>
        </Card>
        
          </Grid>
          <Grid item > 
          <Card gap={10} variant="outlined" 
           >
          <CardContent   sx={{padding:"0px"}} />

          <CardActions style={{justifyContent:"space-between",background:"#5f73b5"}} disable>
          <p className="main-titles">4. Evolution of the Child Life Experience</p>
            <IconButton
              onClick={() => handleExpandClick(4)}
              aria-label="show more"
            >
              <ExpandMoreIcon style={{color:"white"}}></ExpandMoreIcon>
            </IconButton>
          </CardActions>
          <Collapse in={expanded===4} timeout="auto" unmountOnExit>
            <CardContent style={{justifyContent:"space-between",background:"#5f73b5"}} sx={{padding:"0px"}}>
            <p className="details-textTops">The Federal Food, Drug, and Cosmetic Act (FD&amp;C Act) says that paediatric patients are people who are 21 years old or younger when they are diagnosed or treated.</p>

<p className="details-texts">There are different categories for paediatric patients:</p>

<p className="details-texttops">Neonates: From birth until 28 days old</p>

<p className="details-texttops">Infants: From 29 days to less than 2 years old</p>

<p className="details-texttops">Children: From 2 years to less than 12 years old</p>

<p className="details-texttops">Adolescents: From 12 years old until their 21st birthday (but not including their 22nd birthday)</p>



<p className="details-texts">Many children have illnesses or long-term conditions like asthma, mental health issues, cancer, or congenital conditions. These conditions can make their lives more complicated, and they may need to go to the hospital more often.</p>

<p className="details-texts">They might have to take medication, undergo medical procedures, and have frequent medical appointments to improve their lives and the lives of their families. A long-term condition is an illness or disease that lasts for more than 6 months and is linked to higher chances of getting sick or dying.</p>

<p className="details-texts">We want to find simple solutions for children in long-term care to have experiences like those of other children.</p>

<p className="details-texts">Things to think about:</p>

<ul className="bullet-pointss">
          {" "}
          <li>{" "}Active Skill Development and Learning: Normal children have an opportunity to actively develop their skills and engage in various learning activities such as sports, hobbies, arts, and education. Paediatric patients, on the other hand, may have limited physical abilities or restrictions that hinder their participation in these activities.</li>
        </ul>

          <ul className="bullet-pointss">
          {" "}
          <li>E{" "}veryday Interactions with Surroundings: Normal children get to explore and interact with their surroundings freely. They can play outdoors, visit different places, and engage in social activities with friends and peers. Paediatric patients often must limit their interactions due to their condition or medical needs, which may restrict their ability to engage with the world around them.</li>
        </ul>

          <ul className="bullet-pointss">
          {" "}
          <li>{" "}Shared Decision-Making Process: Normal children are often involved in decision-making processes that affect their lives, such as choosing activities, making simple choices, and expressing their preferences. Paediatric patients may have limited autonomy or involvement in decision-making regarding their healthcare, as their medical condition and treatment plans are primarily determined by medical professionals and caregivers.</li>
        </ul>



<ul className="bullet-pointss">
          {" "}
          <li>These are just a few examples, and there may be other experiences that normal children have but paediatric patients may miss out on due to their long-term care.</li>
        </ul>


   
            </CardContent>
          </Collapse>
        </Card>
        
          </Grid> */}
    </div>
  );
};

export default Themes;
