import React from "react";
const EligibilityList = [
  {
    Students: "UG, PG, Ph.D.",
    WorkingProfessionals:
      "Working Professional in all technology firms, medical profession, consulting firms, research domain, healthcare & pharma industry",
  },

  {
    Students:
      "Engineering, Science, Medical, Mathematics, Statistics or any other technology or science field.",
    WorkingProfessionals:
      "We recommend that the teams are made up of specialists from various fields of activity",
  },
  {
    Students: "Specialized courses in technology and healthcare",
    WorkingProfessionals:
      "Teams should be made up exclusively of working or non-working professionals. Students are not allowed to be a part of the team",
  },
];
const Eligibility = (children) => {
  const renderHeaderEligibility = () => {
    let headerElement = ["Students", "Working Professionals"];

    return headerElement.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };
  const renderBodyEligibility = () => {
    return (
      EligibilityList &&
      EligibilityList.map(({ Students, WorkingProfessionals }) => {
        return (
          <tr key={Students}>
            <td>{Students}</td>
            <td>{WorkingProfessionals}</td>
          </tr>
        );
      })
    );
  };
  let { toggle } = children;
  return (
    <div style={{ marginBottom: "50px", marginLeft: "1rem",marginRight:'1rem' }}>
      <p className="main-title">Eligibility Criteria</p>
      {/* <div style={{justifyContent:"center",display:"flex"}}> */}
      <table id="prizeMain" style={{ marginBottom: "50px" }}>
        <thead>
          <tr>{renderHeaderEligibility()}</tr>
        </thead>
        <tbody>{renderBodyEligibility()}</tbody>
      </table>
      {/* </div> */}
    </div>
  );
};

export default Eligibility;
