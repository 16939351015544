import { Box, Divider, FormControlLabel, Typography } from "@mui/material";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import { withStyles, MenuItem, Card } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { teal, grey } from "@material-ui/core/colors";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Uploader } from "rsuite";
import AnimatedModal from "../../components/AnimatedModal";
import AnimatedModalerror from "../../components/AnimatedModalError";
import { motion, Animaepresence } from "framer-motion";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import { appConfig } from "../../config";
import CircularProgress from "@mui/material/CircularProgress";
import 'rsuite/dist/rsuite.min.css';
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import parsePhoneNumberFromString from 'libphonenumber-js/max';
import { Modal, Button, Placeholder } from 'rsuite';
import { Message } from 'rsuite';
import "./topbar.css"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  primaryColor: {
    color: teal[500],
  },
  secondaryColor: {
    color: grey[700],
  },

  padding: {
    padding: 0,
  },
  mainHeader: {
    backgroundColor: grey[100],
    padding: 20,
    alignItems: "center",
  },
  mainContent: {
    padding: 40,
  },
  secondaryContainer: {
    padding: "20px 25px",
    backgroundColor: grey[200],
  },
});
function Topbar(props) {
  function isValidPhoneNumber(phonenumber) {
    
  
}
  const [checked, setChecked] = React.useState(false);
  const handleChange = (event) => {
    if (!checked) {
      let detaill = userdetails;
      detaill.teamDetails[0].email = detaill.email;
      detaill.teamDetails[0].name = detaill.name;
      detaill.teamDetails[0].mobileNumber = detaill.mobile;
      detaill.teamDetails[0].professionName = detaill.professionName;

      setuserDetails({ ...detaill });
      checkteamPeople.email = false;
      checkteamPeople.name = false;
      checkteamPeople.mobileNumber = false;
      checkteamPeople.professionName = false;

      checkteamPeople.github = false;
      checkteamPeople.linkedIn = false;
    } else {
      let detaill = userdetails;
      detaill.teamDetails[0].email = "";
      detaill.teamDetails[0].name = "";
      detaill.teamDetails[0].mobileNumber = "";
      detaill.teamDetails[0].professionName = "";

      setuserDetails({ ...detaill });
    }
    // detaill.teamDetails.push({ ...teamPeople });
    // check.teamDetails.push({ ...checkteamPeople });
    setChecked(event.target.checked);
  };
  const [response, setResponse] = React.useState("");
  const [successResponse, setsuccessResponse] = React.useState("");

  const [updateOpen, setupdateOpen] = React.useState(false);
  let filelist = [];
  // const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const [progressBar, setProgressBar] = React.useState(false);

  const [uploadedFile, setUploadedFile] = React.useState(null);
  const [usersCount, setUsersCount] = React.useState("0");
  
  const [userdetails, setuserDetails] = React.useState({
    name: "",
    email: "",
    mobile: "",
    professionType: "",
    professionName: "",
    technology: "",
    teamSize: 0,
    teamName: "",
    country: "",
    teamDetails: [],
    filePath: {
      bluePrint: "",
      architecture: "",
    },
  });
  const [updateUserDetails, setupdateUserDetails] = React.useState({
    email: "",
    name: "",
    filePath: {
      bluePrint: "",
      architecture: "",
    },
  });
  const handleuploadfile = (fileList) => {
    let fileUploads = fileUpload;
    fileUploads.bluePrint = fileList[0];
    setFileUpload({ ...fileUploads });
  };
  const handleUploadFileArchitecture = (fileList) => {
    let fileUploads = fileUpload;
    fileUploads.architecture = fileList[0];
    setFileUpload({ ...fileUploads });
  };
  const [updateCheck, setUpdateCheck] = React.useState({
    email: false,
    name: false,
    filePath: {
      bluePrint: false,
      architecture: false,
    },
  });

  const [fileUpload, setFileUpload] = React.useState({
    bluePrint: "",
    architecture: "",
  });

  const [check, setCheck] = React.useState({
    name: false,
    email: false,
    mobile: false,
    professionType: false,
    technology: false,
    professionName: false,
    teamSize: false,
    teamName: false,
    country: false,
    teamDetails: [],
    filePath: {
      bluePrint: false,
      architecture: false,
    },
  });

  let checkteamPeople = {
    professionName: false,
    name: false,
    email: false,
    mobileNumber: false,
    github: false,
    linkedIn: false,
  };

  let teamPeople = {
    professionName: "",
    name: "",
    email: "",
    mobileNumber: "",
    github: "",
    linkedIn: "",
  };
  const updateHandleClosed = () => {
    setFileUpload({
      bluePrint: "",
      architecture: "",
    });
    setupdateUserDetails({
      email: "",
      name: "",
      filePath: "",
    });
    setupdateOpen(false);
  };
  const updateHandleClickOpen = () => {
    setupdateOpen(true);
  };
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const handleClose = () => {
    setChecked(false);
    setuserDetails({
      name: "",
      email: "",
      mobile: "",
      professionType: "Student",
      professionName: "",
      technology: "",
      teamSize: 0,
      teamName: "",
      country: "",
      teamDetails: [],
      filePath: {
        bluePrint: "",
        architecture: "",
      },
    });
    setFileUpload({
      bluePrint: "",
      architecture: "",
    });
    setCheck({
      name: false,
      email: false,
      mobile: false,
      professionType: false,
      technology: false,
      professionName: false,
      teamSize: false,
      teamName: false,
      country: false,
      teamDetails: [],
      filePath: {
        bluePrint: false,
        architecture: false,
      },
    });
    setOpen(false);
  };
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  useEffect(() => {
    axios
      .get(`${appConfig.api}/total-registered-user`)
      .then(function (response) {
        setUsersCount(response.data["total_User_Registered"]);
      })
      .catch((e) => {});
    // setInterval(async () => {
    //   await axios
    //     .get(`${appConfig.api}/total-registered-user`)
    //     .then(function (response) {
    //       if (response?.data[0]["total_User_Registered"] !== usersCount) {
    //         setUsersCount(response?.data[0]["total_User_Registered"]);
    //       }
    //     })
    //     .catch((e) => {});
    // }, 10000);
  }, []);

  const { classes, handleClickOpen, open, setOpen } = props;
  const [alertOpen, setAlertOpen] = useState(false)
  // const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const handleAlertOpen = (item) => {
    setAlertOpen(true)
  }
  const handleAlertClose = () => {
    setAlertOpen(false)
  }

  return (
    <div paddingBottom={0} style={{ backgroundColor: "rgba(245,247,247,255)" }}>
    
      <AnimatedModalerror
        opens={success}
        setSuccess={setSuccess}
        
      ></AnimatedModalerror>
      <Message style={{backgroundColor:'#fcf493',height:'50px'}} closable full={false} showIcon type='info'>The idea submission phase is over and participation is closed.</Message>
       
      <Box>
        <Box style={{ background: "rgba(245,247,247,255)" }}>
          <Box
            boxShadow={10}
            borderRadius={1}
            display="flex"
            textAlign={"right"}
            style={{
              backgroundColor: "#f9f9f9",
              position: "absolute",
              marginTop: "34vh",
              marginLeft: "4vw",
              paddingTop: "3vh",
              paddingBottom: "3vh",
            }}
          >
            {" "}
          </Box>
        </Box>

        <img
          style={{ width: "100%" }}
          src={`banner.png`}
          alt={"manoj"}
          loading="lazy"
        />
      </Box>

      <Grid container spacing={5} style={{ width: "100%", margin: "1px" }}>
        <Grid item xs={12} md={6} className="containergrid">
          <Box
            justifyContent="center"
            justifyItems="center"
            textAlign="center"
            display="flex"
            marginTop="20px"
            marginBottom="0px"
          >
           
            <Card
              style={{
                display: "grid",
                width: "100%",
                borderRadius: "20px",
                marginBottom: "10px",
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                textAlign="center"
                justifyItems="center"
              >
                <img
                  className="main-title"
                  style={{
                    width: "24px",
                    height: "24px",
                    marginTop: "10px",
                    marginRight: "2px",
                  }}
                  src={`download__1_-removebg-preview.png`}
                  alt={"manoj"}
                  loading="lazy"
                />
                <div
                  className="main-title"
                  style={{
                    marginTop: "12px",
                    marginLeft: "1px",
                    marginRight: "20px",
                    justifyContent: "center",
                  }}
                >
                  BREAKTHROUGH 2023 - Solutionec Hackathon
                </div>
              </Box>

              <p className="details-text" style={{ textAlign: "justify" }}>
                We at Solutionec, bring you the most exciting Hackathon-{" "}
                <span
                  style={{
                    fontFamily: "Arial, Verdana, sans-serif",
                    fontWeight: "bold",
                    color: "#052a58",
                    cursor: "pointer",
                  }}
                >
                  BREAKTHROUGH 2023.
                </span>{" "}
                Unleashing in July, the event is focused on creating a platform
                for outstanding individuals to define the next generation AI
                revolution in Healthcare services and offerings.
              </p>

              <p></p>

              <p className="details-text" style={{ textAlign: "justify" }}>
                Growing at a rapid pace, the healthcare domain has a massive
                potential to offer, and a more seamless experience is the need
                of the hour! We envision a data-empowered future for the various
                stakeholders in healthcare.
              </p>

              <p></p>

              <p className="details-text" style={{ textAlign: "justify" }}>
                BREAKTHROUGH 2023 creates opportunities for unheard talents to
                make their marks in the healthcare industry and identify
                solutions to provide better support and services to patients and
                professionals.
              </p>
              <div
                className="details-text"
                style={{ marginBottom: "20px", textAlign: "justify" }}
              >
                <span
                  className="sub-title"
                  style={{
                    marginLeft: "1px",
                    color: "#052a58",
                    marginRight: "2px",
                    cursor: "pointer",
                  }}
                  onClick={handleClickOpen}
                >
                  Register today{" "}
                </span>
                and dive deep into this hackathon extravaganza!{" "}
              </div>
            </Card>
          </Box>{" "}
        </Grid>
        <Grid item xs={12} md={6} className="containergrid">
          <Box
            justifyContent="center"
            justifyItems="center"
            textAlign="center"
            display="flex"
            marginTop="20px"
            marginBottom="0px"
          >
            <Card
              textAlign="center"
              justifyContent="center"
              style={{
                display: "grid",
                width: "100%",
                borderRadius: "20px",
                marginBottom: "10px",
                textAlign: "center",
              }}
            >
              <Box
                pt={1}
                textAlign="center"
                style={{
                  backgroundColor: "white",
                  borderRadius: 5,
                  borderColor: "3px solid white",
                }}
              >
                <Box
                  display="flex"
                  mr={1}
                  ml={1}
                  sx={{ flex: 1, alignItems: "center" }}
                  style={{ backgroundColor: "#f9f9f9" }}
                >
                  <Box
                    display="flex"
                    p={1}
                    marginX="1vw"
                    justifyContent="center"
                    justifyItems="center"
                    sx={{ flex: 1, alignItems: "center" }}
                  >
                    <Diversity3Icon style={{ color: "gray" }} />
                    <Typography
                      color="black"
                      fontSize={11}
                      style={{
                        fontFamily: "Arial, Verdana, sans-serif",
                        verticalAlign: "middle",
                        marginLeft: "10px",
                      }}
                    >
                      {usersCount} Registered Participants
                    </Typography>
                    <Typography
                      color="black"
                      fontSize={11}
                      style={{
                        fontFamily: "Arial, Verdana, sans-serif",
                        marginLeft: "15px",
                        verticalAlign: "middle",
                      }}
                    >
                      ALLOWED TEAM SIZE: 1 - 5
                    </Typography>
                  </Box>
                </Box>
                <Divider
                  orientation="horizontal"
                  flexItem
                  thiccness={1}
                  sx={{
                    borderBottomWidth: "1px",
                    marginX: "1vw",
                    marginTop: "15px",
                  }}
                ></Divider>
                <Box
                  pt={1}
                  justifyContent="space-between"
                  marginX="1vw"
                  sx={{ flex: 1, alignItems: "center" }}
                >
                  <Grid
                    container
                    spacing={2}
                    display="flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <Grid item xs={4} md={4}>
                      <Box
                        justifyContent="left"
                        justifyItems="left"
                        textAlign="left"
                      >
                        <Typography
                          color="#9ca3a8"
                          fontSize={12}
                          justifyContent="left"
                          style={{
                            fontFamily: "Arial, Verdana, sans-serif",
                            justifyContent: "left",
                            justifyItems: "left",
                            verticalAlign: "left",
                          }}
                        >
                          STARTS ON:
                        </Typography>
                        <Typography
                          color="#46535e"
                          fontSize={14}
                          fontWeight="600"
                          justifyContent="center"
                          style={{
                            fontFamily: "Arial, Verdana, sans-serif",
                            verticalAlign: "middle",
                          }}
                        >
                          July 25, 2023
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Box
                        justifyContent="left"
                        justifyItems="left"
                        textAlign="left"
                      >
                        <Typography
                          color="#9ca3a8"
                          fontSize={12}
                          style={{
                            fontFamily: "Arial, Verdana, sans-serif",
                            verticalAlign: "middle",
                          }}
                        >
                          IDEA PHASE
                        </Typography>
                        <Typography
                          color="#46535e"
                          fontSize={14}
                          fontWeight="600"
                          style={{
                            fontFamily: "Arial, Verdana, sans-serif",
                            verticalAlign: "middle",
                          }}
                        >
                          Online
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Box
                        justifyContent="left"
                        justifyItems="left"
                        textAlign="left"
                      >
                        <Typography
                          color="#9ca3a8"
                          fontSize={12}
                          style={{
                            fontFamily: "Arial, Verdana, sans-serif",
                            verticalAlign: "middle",
                          }}
                        >
                          ENDS ON:
                        </Typography>
                        <Typography
                          color="#46535e"
                          fontSize={14}
                          fontWeight="600"
                          style={{
                            fontFamily: "Arial, Verdana, sans-serif",
                            verticalAlign: "middle",
                          }}
                        >
                          Aug 29, 2023
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Divider
                  orientation="horizontal"
                  flexItem
                  thiccness={1}
                  sx={{
                    borderBottomWidth: "1px",
                    marginX: "1vw",
                    marginTop: "10px",
                  }}
                ></Divider>

                <Box
                  pt={1}
                  justifyContent="space-between"
                  marginX="1vw"
                  sx={{ flex: 1, alignItems: "center" }}
                >
                  <Grid
                    container
                    spacing={2}
                    display="flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <Grid item xs={4} md={4}>
                      <Box
                        justifyContent="left"
                        justifyItems="left"
                        textAlign="left"
                      >
                        <Typography
                          color="#9ca3a8"
                          fontSize={12}
                          style={{
                            fontFamily: "Arial, Verdana, sans-serif",
                            verticalAlign: "middle",
                          }}
                        >
                          STARTS ON:
                        </Typography>
                        <Typography
                          color="#46535e"
                          fontSize={14}
                          fontWeight="600"
                          style={{
                            fontFamily: "Arial, Verdana, sans-serif",
                            verticalAlign: "middle",
                          }}
                        >
                          October, 2023
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Box
                        justifyContent="left"
                        justifyItems="left"
                        textAlign="left"
                      >
                        <Typography
                          color="#9ca3a8"
                          fontSize={12}
                          style={{
                            fontFamily: "Arial, Verdana, sans-serif",
                            verticalAlign: "middle",
                          }}
                        >
                          HACKATHON
                        </Typography>
                        <Typography
                          color="#46535e"
                          fontSize={14}
                          fontWeight="600"
                          style={{
                            fontFamily: "Arial, Verdana, sans-serif",
                            verticalAlign: "middle",
                          }}
                        >
                          Solutionec Office
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Box
                        justifyContent="left"
                        justifyItems="left"
                        textAlign="left"
                      >
                        <Typography
                          color="#9ca3a8"
                          fontSize={12}
                          style={{
                            fontFamily: "Arial, Verdana, sans-serif",
                            verticalAlign: "middle",
                          }}
                        >
                          ENDS ON:
                        </Typography>
                        <Typography
                          color="#46535e"
                          fontSize={14}
                          fontWeight="600"
                          style={{
                            fontFamily: "Arial, Verdana, sans-serif",
                            verticalAlign: "middle",
                          }}
                        >
                          October, 2023
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <p className="main-titletheams" style={{ marginLeft: "8px" }}>
                    {" "}
                    <p
                      className="sub-title"
                      style={{
                        marginLeft: "1px",
                        color: "#052a58",
                        fontSize: "13px",
                        marginTop: "5px",
                      }}
                    >
                      NOTE: The hack date will be announced soon.
                    </p>
                  </p>
                </Grid>
              </Grid>

              <Box
                display="flex"
                flexDirection="row"
                padding={1}
                justifyContent="center"
                alignItems="center"
                
              >
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <motion.button
                      style={{
                        fontFamily: "Arial, Verdana, sans-serif",
                        justifyContent: "center",
                        background: "rgb(102, 108, 116)",
                        color: "white",
                        marginTop: "10px",
                        width: "90%",
                        borderRadius: 5,
                        border: "none",
                        padding: 10,

                        cursor: "not-allowed",
                      }}
                      whileHover={{ scale: 1.01 }}
                      // whileTap={{ scale: 0.9, x: "-5px", y: "5px" }}
                      variant="outlined"
                      // onClick={handleClickOpen}
                    >
                      New Registration
                    </motion.button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <motion.button
                      style={{
                        justifyContent: "center",
                        background: "white",
                        color: "#052a58",
                        borderRadius: 5,
                        width: "90%",
                        marginTop: "10px",
                        border: "1px solid #052a58",
                        padding: 10,
                        cursor: "not-allowed",
                        fontFamily: "Arial, Verdana, sans-serif",
                      }}
                      variant="outlined"
                      // onClick={updateHandleClickOpen}
                      whileHover={{ scale: 1.01 }}
                      // whileTap={{ scale: 0.9, x: "-5px", y: "5px" }}
                    >
                      Already Registered User
                    </motion.button>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Box>
        </Grid>
      </Grid>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Dialog
          fullWidth
          maxWidth="md"
          open={true}
          // onClose={() => onClose(true)}
        >
          <DialogContent>
            <AnimatedModal
              open={response !== ""}
              message={response}
              setResponse={setResponse}
            ></AnimatedModal>
            <AnimatedModal
              open={response !== ""}
              message={response}
              setResponse={setResponse}
            ></AnimatedModal>
            <Grid container>
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Typography
                    style={{
                      fontFamily: "Arial, Verdana, sans-serif",
                      color: " rgb(0, 7, 61)",
                      margin: "auto",
                    }}
                    variant="h5"
                  >
                    Hackathon Registration
                  </Typography>
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>

                <Grid
                  container
                  direction="column"
                  className={classes.mainContent}
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <Typography
                      style={{ color: " rgb(0, 7, 61)", marginTop: "0px" }}
                    >
                      Personal Details
                    </Typography>
                  </Grid>
                  <Grid container direction="row" spacing={1}>
                    <Grid style={{ marginTop: "5px" }} item xs={12} md={4}>
                      <TextField
                        required
                        inputProps={{
                          style: {
                            fontFamily: "nunito",
                            color: "rgb(0, 7, 61)",
                          },
                        }}
                        id="outlined-required"
                        label="Name"
                        size="small"
                        fullWidth
                        variant="outlined"
                        value={userdetails.name}
                        error={check.name}
                        onChange={(e) => {
                          if (check.name) {
                            check.name = false;
                            setCheck({ ...check });
                          }
                          if (checked) {
                            if (check.teamDetails[0].name) {
                              check.teamDetails[0].name = false;
                              setCheck({ ...check });
                            }
                            let detaill = userdetails;
                            detaill.teamDetails[0]["name"] = e.target.value;
                            setuserDetails({ ...detaill });
                          }
                          let detaill = userdetails;
                          detaill.name = e.target.value;
                          setuserDetails({ ...detaill });
                        }}
                      />
                    </Grid>
                    <Grid style={{ marginTop: "5px" }} item xs={12} md={4}>
                      <TextField
                        required
                        id="outlined-required"
                        label="Email"
                        size="small"
                        inputProps={{
                          style: {
                            fontFamily: "nunito",
                            color: "rgb(0, 7, 61)",
                            borderColor: "3px solid red",
                          },
                        }}
                        fullWidth
                        variant="outlined"
                        value={userdetails.email}
                        error={check.email}
                        onChange={(e) => {
                          if (check.email) {
                            check.email = false;
                            setCheck({ ...check });
                          }
                          if (checked) {
                            if (check.teamDetails[0].email) {
                              check.teamDetails[0].email = false;
                              setCheck({ ...check });
                            }
                            let detaill = userdetails;
                            detaill.teamDetails[0]["email"] = e.target.value;
                            setuserDetails({ ...detaill });
                          }
                          let detaill = userdetails;
                          detaill.email = e.target.value;
                          setuserDetails({ ...detaill });
                        }}
                      />
                    </Grid>
                    <Grid style={{ marginTop: "5px" }} item xs={12} md={4}>
                      <PhoneInput
                        inputProps={{
                          style: {
                            fontFamily: "nunito",
                            color: "rgb(0, 7, 61)",
                            borderColor: "3px solid red",
                            width: "100%",
                            height: "41px",
                          },
                        }}
                        country={"in"}
                        id="outlined-required"
                        label="Mobile"
                        type="text"
                        
                        fullWidth
                        variant="outlined"
                        value={userdetails.mobile}
                        error={check.mobile}
                        isValid={(value, country) => {           
                          if(check.mobile){
                            return 'Invalid value number ';
                          }
                          if (value.match(/12345/)) {                           
                          } else if (value.match(/1234/)) {
                            return false;
                          } else {
                            return true;
                          }
                        }}
                        onChange={(value,country,e) => {
                           let detaill = userdetails;
                          detaill.country = country.name
                          setuserDetails({ ...detaill });
                          

                          if (checked) {
                            if (check.teamDetails[0].mobileNumber) {
                              check.teamDetails[0].mobileNumber = false;
                              setCheck({ ...check });
                            }
                            let detaill = userdetails;
                            detaill.teamDetails[0].mobileNumber = value;
                            setuserDetails({ ...detaill });
                          }
                          detaill.mobile = value;
                          setuserDetails({ ...detaill });

                          if (check.mobile) {
                            check.mobile = false;
                            setCheck({ ...check });
                          }
                        }}
                      />
                     

                      {/* <TextField
                        required
                        inputProps={{
                          style: {
                            fontFamily: "nunito",
                            color: "rgb(0, 7, 61)",
                            borderColor: "3px solid red",
                          },
                        }}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 10);
                        }}
                        id="outlined-required"
                        label="Mobile"
                        type="text"
                        size="small"
                        fullWidth
                        variant="outlined"
                        value={userdetails.mobile}
                        error={check.mobile}
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            if (checked) {
                              if (check.teamDetails[0].mobile) {
                                check.teamDetails[0].mobile = false;
                                setCheck({ ...check });
                              }
                              let detaill = userdetails;
                              detaill.teamDetails[0]["mobileNumber"] =
                                e.target.value;
                              setuserDetails({ ...detaill });
                            }
                            let detaill = userdetails;
                            detaill.mobile = e.target.value;
                            setuserDetails({ ...detaill });
                          }else{
                            if (checked) {
                              if (check.teamDetails[0].mobile) {
                                check.teamDetails[0].mobile = false;
                                setCheck({ ...check });
                              }
                              let detaill = userdetails;
                              detaill.teamDetails[0]["mobileNumber"] =
                                "";
                              setuserDetails({ ...detaill });
                            }
                            let detaill = userdetails;
                            detaill.mobile = "";
                            setuserDetails({ ...detaill });
                          }
                          if (check.mobile) {
                            check.mobile = false;
                            setCheck({ ...check });
                          }
                        }}
                      /> */}
                    </Grid>
                    <Grid style={{ marginTop: "5px" }} item xs={12} md={4}>
                      <FormControl
                        fullWidth
                        sx={{ minWidth: 120 }}
                        size="small"
                      >
                        <InputLabel
                          id="demo-simple-select-label"
                          inputProps={{
                            style: {
                              fontFamily: "nunito",
                              color: "rgb(0, 7, 61)",
                              borderColor: "3px solid red",
                            },
                          }}
                        >
                          Profession Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={userdetails.professionType}
                          inputProps={{
                            style: {
                              fontFamily: "nunito",
                              color: "rgb(0, 7, 61)",
                              borderColor: "3px solid red",
                            },
                          }}
                          style={{ fontFamily: "nunito" }}
                          label="Profession Type"
                          error={check.professionType}
                          onChange={(e) => {
                            if (check.professionType) {
                              check.professionType = false;
                              setCheck({ ...check });
                            }
                            let detaill = userdetails;
                            detaill.professionType = e.target.value;
                            setuserDetails({ ...detaill });
                          }}
                        >
                          <MenuItem
                            style={{ fontFamily: "nunito" }}
                            value={"Student"}
                          >
                            Student
                          </MenuItem>
                          <MenuItem
                            style={{ fontFamily: "nunito" }}
                            value={"Working Professional"}
                          >
                            Working Professional
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item style={{ marginTop: "5px" }} xs={12} md={4}>
                      <TextField
                        required
                        id="outlined-required"
                        label={
                          userdetails.professionType === "Student"
                            ? "College Name"
                            : "Company Name"
                        }
                        size="small"
                        inputProps={{
                          style: {
                            fontFamily: "nunito",
                            color: "rgb(0, 7, 61)",
                            borderColor: "3px solid red",
                          },
                        }}
                        fullWidth
                        variant="outlined"
                        value={userdetails.professionName}
                        error={check.professionName}
                        onChange={(e) => {
                          if (check.professionName) {
                            check.professionName = false;
                            setCheck({ ...check });
                          }
                          if (checked) {
                            if (check.teamDetails[0].professionName) {
                              check.teamDetails[0].professionName = false;
                              setCheck({ ...check });
                            }
                            let detaill = userdetails;
                            detaill.teamDetails[0]["professionName"] =
                              e.target.value;
                            setuserDetails({ ...detaill });
                          }
                          let detaill = userdetails;
                          detaill.professionName = e.target.value;
                          setuserDetails({ ...detaill });
                        }}
                      />
                    </Grid>
                    <Grid item style={{ marginTop: "5px" }} xs={12} md={4}>
                      <TextField
                        required
                        id="outlined-required"
                        label={"Country"}
                        size="small"
                        inputProps={{
                          style: {
                            fontFamily: "nunito",
                            color: "rgb(0, 7, 61)",
                            borderColor: "3px solid red",
                          },
                        }}
                        fullWidth
                        variant="outlined"
                        disabled="false"

                        value={userdetails.country}
                        error={check.country}
                        onChange={(e) => {
                          if (check.country) {
                            check.country = false;
                            setCheck({ ...check });
                          }
                          let detaill = userdetails;
                          detaill.country = e.target.value;
                          setuserDetails({ ...detaill });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        style={{ color: " rgb(0, 7, 61)", marginTop: "10px" }}
                      >
                        Team Details
                      </Typography>
                    </Grid>

                    <Grid item style={{ marginTop: "10px" }} xs={12} md={4}>
                      <TextField
                        required
                        id="outlined-required"
                        label={"Team Name"}
                        size="small"
                        inputProps={{
                          style: {
                            fontFamily: "nunito",
                            color: "rgb(0, 7, 61)",
                            borderColor: "3px solid red",
                          },
                        }}
                        fullWidth
                        variant="outlined"
                        value={userdetails.teamName}
                        error={check.teamName}
                        onChange={(e) => {
                          if (check.teamName) {
                            check.teamName = false;
                            setCheck({ ...check });
                          }
                          let detaill = userdetails;
                          detaill.teamName = e.target.value;
                          setuserDetails({ ...detaill });
                        }}
                      />
                    </Grid>
                    <Grid item style={{ marginTop: "10px" }} xs={12} md={4}>
                      <FormControl
                        fullWidth
                        sx={{ minWidth: 120 }}
                        size="small"
                      >
                        <InputLabel
                          id="demo-simple-select-label"
                          inputProps={{
                            style: {
                              fontFamily: "nunito",
                              color: "rgb(0, 7, 61)",
                            },
                          }}
                        >
                          Team Size
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={userdetails.teamSize}
                          inputProps={{
                            style: {
                              fontFamily: "nunito",
                              color: "rgb(0, 7, 61)",
                              borderColor: "3px solid red",
                            },
                          }}
                          label="Team Size"
                          error={check.teamSize}
                          style={{ fontFamily: "nunito" }}
                          onChange={(e) => {                           
                            let detaill = userdetails;
                            detaill.teamSize = e.target.value;
                            userdetails.teamDetails = new Array();
                            check.teamDetails = new Array();
                            if (check.teamSize) {
                              check.teamSize = false;
                              setCheck({ ...check });
                            }
                            for (let i = 0; i < detaill.teamSize; i++) {
                              detaill.teamDetails.push({ ...teamPeople });
                              check.teamDetails.push({ ...checkteamPeople });
                            }
                            setChecked(false);
                            setuserDetails({ ...detaill });
                          }}
                        >
                          <MenuItem style={{ fontFamily: "nunito" }} value={1}>
                            1
                          </MenuItem>
                          <MenuItem style={{ fontFamily: "nunito" }} value={2}>
                            2
                          </MenuItem>
                          <MenuItem style={{ fontFamily: "nunito" }} value={3}>
                            3
                          </MenuItem>
                          <MenuItem style={{ fontFamily: "nunito" }} value={4}>
                            4
                          </MenuItem>
                          <MenuItem style={{ fontFamily: "nunito" }} value={5}>
                            5
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item style={{ marginTop: "10px" }} xs={12} md={4}>
                      <TextField
                        required
                        id="outlined-required"
                        label={"Technology"}
                        size="small"
                        inputProps={{
                          style: {
                            fontFamily: "nunito",
                            color: "rgb(0, 7, 61)",
                            borderColor: "3px solid red",
                          },
                        }}
                        fullWidth
                        variant="outlined"
                        value={userdetails.technology}
                        error={check.technology}
                        onChange={(e) => {
                          if (check.technology) {
                            check.technology = false;
                            setCheck({ ...check });
                          }
                          let detaill = userdetails;
                          detaill.technology = e.target.value;
                          setuserDetails({ ...detaill });
                        }}
                      />
                    </Grid>
                  </Grid>

                  {/* <Grid item xs={12}>
                    <Typography
                      style={{
                        color: " rgb(0, 7, 61)",
                        marginTop: "10px",
                        visibility:
                          userdetails.teamDetails.length >= 1
                            ? "visible"
                            : "hidden",
                      }}
                    >
                    Team Details
                    </Typography>
                  </Grid> */}
                  {userdetails.teamDetails.map((itinerary, i) => {
                    return (
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid container direction="row" spacing={1}>
                            <Grid item xs={12}>
                              <Typography
                                style={{
                                  color: " rgb(0, 7, 61)",
                                  marginTop: "10px",
                                }}
                              >
                                {i + 1}.{" "}
                                {i === 0
                                  ? "Team Leader Details"
                                  : "Participants Details"}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              style={{ marginTop: "5px" }}
                              xs={12}
                              md={3}
                            >
                              <TextField
                                required
                                id="outlined-required"
                                label={
                                  userdetails.professionType === "Student"
                                    ? "College Name"
                                    : "Company Name"
                                }
                                size="small"
                                inputProps={{
                                  style: {
                                    fontFamily: "nunito",
                                    color: "rgb(0, 7, 61)",
                                    borderColor: "3px solid red",
                                  },
                                }}
                                fullWidth
                                variant="outlined"
                                value={
                                  userdetails.teamDetails[i].professionName
                                }
                                error={check.teamDetails[i].professionName}
                                onChange={(e) => {
                                  if (check.teamDetails[i].professionName) {
                                    check.teamDetails[i].professionName = false;
                                    setCheck({ ...check });
                                  }
                                  let detaill = userdetails;
                                  detaill.teamDetails[i].professionName =
                                    e.target.value;
                                  setuserDetails({ ...detaill });
                                }}
                                
                                disabled={i === 0 && checked ? true : false
                                }
                              />
                            </Grid>
                            <Grid
                              style={{ marginTop: "5px" }}
                              item
                              xs={12}
                              md={3}
                            >
                              <TextField
                                required
                                id="outlined-required"
                                label="Name"
                                size="small"
                                fullWidth
                                inputProps={{
                                  style: {
                                    fontFamily: "nunito",
                                    color: "rgb(0, 7, 61)",
                                    borderColor: "3px solid red",
                                  },
                                }}
                                variant="outlined"
                                value={userdetails.teamDetails[i].name}
                                error={check.teamDetails[i].name}
                                onChange={(e) => {
                                  if (check.teamDetails[i].name) {
                                    check.teamDetails[i].name = false;
                                    setCheck({ ...check });
                                  }
                                  let detaill = userdetails;
                                  detaill.teamDetails[i]["name"] =
                                    e.target.value;
                                  setuserDetails({ ...detaill });
                                }}
                                
                                disabled={i === 0 && checked ? true : false
                                 }
                              />
                            </Grid>
                            <Grid
                              style={{ marginTop: "5px" }}
                              item
                              xs={12}
                              md={3}
                            >
                              <TextField
                                required
                                id="outlined-required"
                                label="Email"
                                size="small"
                                fullWidth
                                inputProps={{
                                  style: {
                                    fontFamily: "nunito",
                                    color: "rgb(0, 7, 61)",
                                    borderColor: "3px solid red",
                                  },
                                }}
                                variant="outlined"
                                value={userdetails.teamDetails[i].email}
                                error={check.teamDetails[i].email}
                                onChange={(e) => {
                                  if (check.teamDetails[i].email) {
                                    check.teamDetails[i].email = false;
                                    setCheck({ ...check });
                                  }
                                  let detaill = userdetails;
                                  detaill.teamDetails[i]["email"] =
                                    e.target.value;
                                  setuserDetails({ ...detaill });
                                }}
                                disabled={i === 0 && checked ? true : false
                                }
                              />
                            </Grid>
                            <Grid
                              style={{ marginTop: "5px" }}
                              item
                              xs={12}
                              md={3}
                            >
                              {/* <TextField
                                required
                                id="outlined-required"
                                label="Mobile"
                                size="small"
                                inputProps={{
                                  style: {
                                    fontFamily: "nunito",
                                    color: "rgb(0, 7, 61)",
                                    borderColor: "3px solid red",
                                  },
                                }}
                                fullWidth
                                onInput={(e) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 10);
                                }}
                                type="text"
                                variant="outlined"
                                value={userdetails.teamDetails[i].mobileNumber}
                                error={check.teamDetails[i].mobileNumber}
                                onChange={(e) => {
                                  if (check.teamDetails[i].mobileNumber) {
                                    check.teamDetails[i].mobileNumber = false;
                                    setCheck({ ...check });
                                  }
                                  if (!isNaN(e.target.value)) {
                                    let detaill = userdetails;
                                    detaill.teamDetails[i]["mobileNumber"] =
                                      e.target.value;
                                    setuserDetails({ ...detaill });
                                  }else{
                                    let detaill = userdetails;
                                    detaill.teamDetails[i]["mobileNumber"] =
                                      "";
                                    setuserDetails({ ...detaill });
                                  }
                                }}
                                disabled={checked?true:false}
                              /> */}
                              <PhoneInput
                                country={"in"}
                                required
                                id="outlined-required"
                                label="Mobile"
                                size="small"
                                inputProps={{
                                  style: {
                                    fontFamily: "nunito",
                                    color: "rgb(0, 7, 61)",
                                    borderColor: "3px solid red",
                                    width: "100%",
                                    height: "41px",
                                  },
                                }}
                                fullWidth
                                onInput={(e) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 10);
                                }}
                                type="text"
                                variant="outlined"
                                value={userdetails.teamDetails[i].mobileNumber}
                                error={check.teamDetails[i].mobileNumber}
                                isValid={(value, country) => {

                                 
                                  if(check.teamDetails[i].mobileNumber){
                                    return 'Invalid value number ';
                                  }
                                  
                                  if (value.match(/12345/)) {
        
                                   
                                  } else if (value.match(/1234/)) {
                                    return false;
                                  } else {
        
                                    return true;
                                  }
                                }}
                                onChange={(e) => {
                                  if (check.teamDetails[i].mobileNumber) {
                                    check.teamDetails[i].mobileNumber = false;
                                    setCheck({ ...check });
                                  }
                                  if (!isNaN(e)) {
                                    let detaill = userdetails;
                                    detaill.teamDetails[i]["mobileNumber"] = e;
                                    setuserDetails({ ...detaill });
                                  } else {
                                    let detaill = userdetails;
                                    detaill.teamDetails[i]["mobileNumber"] = "";
                                    setuserDetails({ ...detaill });
                                  }
                                }}
                                disabled={i === 0 && checked ? true : false
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid container direction="row" spacing={1}>
                            <Grid
                              style={{ marginTop: "5px" }}
                              item
                              xs={12}
                              md={3}
                            >
                              <TextField
                                id="outlined-required"
                                label="GitHub Link"
                                size="small"
                                fullWidth
                                inputProps={{
                                  style: {
                                    fontFamily: "nunito",
                                    color: "rgb(0, 7, 61)",
                                    borderColor: "3px solid red",
                                  },
                                }}
                                variant="outlined"
                                value={userdetails.teamDetails[i].github}
                                error={check.teamDetails[i].github}
                                onChange={(e) => {
                                  if (check.teamDetails[i].github) {
                                    check.teamDetails[i].github = false;
                                    setCheck({ ...check });
                                  }
                                  let detaill = userdetails;
                                  detaill.teamDetails[i]["github"] =
                                    e.target.value;
                                  setuserDetails({ ...detaill });
                                }}
                              />
                            </Grid>
                            <Grid
                              style={{ marginTop: "5px" }}
                              item
                              xs={12}
                              md={3}
                            >
                              <TextField
                                id="outlined-required"
                                label="LinkedIn Link"
                                size="small"
                                fullWidth
                                inputProps={{
                                  style: {
                                    fontFamily: "nunito",
                                    color: "rgb(0, 7, 61)",
                                    borderColor: "3px solid red",
                                  },
                                }}
                                variant="outlined"
                                value={userdetails.teamDetails[i].linkedIn}
                                error={check.teamDetails[i].linkedIn}
                                onChange={(e) => {
                                  if (check.teamDetails[i].linkedIn) {
                                    check.teamDetails[i].linkedIn = false;
                                    setCheck({ ...check });
                                  }
                                  let detaill = userdetails;
                                  detaill.teamDetails[i]["linkedIn"] =
                                    e.target.value;
                                  setuserDetails({ ...detaill });
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            style={{ marginTop: "8px", marginLeft: "8px" }}
                            item
                            xs={12}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checked}
                                  color="#052a58"
                                  onChange={handleChange}
                                />
                              }
                              style={{
                                visibility: i === 0 ? "visible" : "hidden",
                              }}
                              label="Same as personal Details"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <Box>
                      <Typography
                        style={{
                          color: " rgb(0, 7, 61)",
                          marginTop: "10px",
                          marginLeft: 40,
                        }}
                      >
                        BluePrint File
                        {alertOpen && (
                          <Message style={{ fontSize: '10px', width: "90%" }} showIcon type='info' closable onClose={handleAlertClose}>
                            A blueprint is a detailed plan outlining the project's purpose, scope, technologies, timelines, user flows, data flows, challenges, and success metrics,
                            <br></br>
                            Note: Allowed file formats doc,pdf,zip,rar,ppt

                          </Message>
                        )}
                      </Typography>
        
                      <Uploader
                        draggable
                        defaultFileList={[]}
                        onChange={handleuploadfile}
                        accept=".doc,.docx,.pdf,.zip,.ppt,.pptx,.rar"
                        autoUpload={false}
                        style={{
                          marginTop: "0.3rem",
                          marginLeft: 40,
                          marginRight: 40,
                        }}
                        multiple={false}
                      >
                        <div
                          style={{
                            height: 150,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "2px dotted gray",
                          }}
                        >
                          <span>Click or Drag files to upload</span>
                        </div>
                      </Uploader>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box>
                      <Typography
                        style={{
                          color: " rgb(0, 7, 61)",
                          marginTop: "10px",
                          marginLeft: 40,
                        }}
                      >
                        Architecture File
                        <Button style={{ width: '25px', height: '25px', marginLeft: '4px',float:"right" }} onClick={() => handleAlertOpen('blueprint')

                        }><img src={`info-file.png`}></img></Button>
                        {alertOpen && (
                          <Message style={{ fontSize: '10px', width: "90%" }} showIcon type='info' closable onClose={handleAlertClose}>
                            Architecture is the visual representation of a system's structure and interactions between components, helping understand the system's design and communication.
                            <br></br>
                            Note: Allowed file formats doc,pdf,zip,rar,ppt

                          </Message>
                        )}
                      </Typography>
                      <Uploader
                        draggable
                        defaultFileList={[]}
                        accept=".doc,.docx,.pdf,.zip,.ppt,.pptx,.rar"
                        onChange={handleUploadFileArchitecture}
                        autoUpload={false}
                        style={{
                          marginTop: "0.3rem",
                          marginLeft: 40,
                          marginRight: 40,
                        }}
                        multiple={false}
                      >
                        <div
                          style={{
                            height: 150,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "2px dotted gray",
                          }}
                        >
                          <span>Click or Drag files to upload</span>
                        </div>
                      </Uploader>
                    </Box>
                  </Grid>
                  <Message style={{ fontSize: '11px', width: "90%", marginLeft: '5%' }} showIcon type='info' closable={false} >
                    <p >
                      For further queries, please reach out to <span>
                        <a style={{ marginLeft: "0px", color: "#052a58", cursor: "pointer" }} href="mailto:hackathon@solutionec.com" target="_blank">

                          hackathon@solutionec.com
                        </a>
                      </span>
                    </p>
                  </Message>
                </Grid>
                {/* <p className="details-texttop" style={{ marginTop: "20px" }}>
                  Note: Please upload the file in the following format:{" "}
                  <a
                    className="sub-title"
                    style={{
                      marginLeft: "1px",
                      color: "#052a58",
                      cursor: "pointer",
                    }}
                  >
                    TeamName-Date of submission (DD_MM_YY)
                  </a>
                  .
                </p> */}

                <Box textAlign="center" marginTop="10px">
                  <motion.button
                    style={{
                      padding: "10px",
                      justifyContent: "center",
                      background: "#052a58",
                      color: "white",
                      borderRadius: 5,
                      borderColor: "#052a58",
                      width: "25%",
                      cursor: "pointer",
                      marginTop: 10,
                    }}
                    type="submit"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9, x: "-5px", y: "5px" }}
                    variant="outlined"
                    onClick={async () => {
                      let x = check;
                      let istrue = false;
                      if (!userdetails.name) {
                        x.name = true;
                        istrue = true;
                      }
                      if (!userdetails.email) {
                        x.email = true;
                        istrue = true;
                      }
                      if (!isValidEmail(userdetails.email)) {
                        x.email = true;
                        istrue = true;
                      }



                      if ( 
                        userdetails.mobile.length > 3
                      ) {
                        const phoneNumberObj = parsePhoneNumberFromString(`+${userdetails.mobile}`);
                        if(!phoneNumberObj.isValid() === true){
                          x.mobile = true;
                          istrue = true;

                      }
                      }else{
                        x.mobile = true;
                        istrue = true;
                      }
                     
                      if (!userdetails.professionType) {
                        x.professionType = true;
                        istrue = true;
                      }
                      if (!userdetails.professionName) {
                        x.professionName = true;
                        istrue = true;
                      }
                      if (!userdetails.teamSize) {
                        x.teamSize = true;
                        istrue = true;
                      }

                      if (!userdetails.country) {
                        x.country = true;
                        istrue = true;
                      }
                      if (!userdetails.technology) {
                        x.technology = true;
                        istrue = true;
                      }
                      if (!userdetails.teamName) {
                        x.teamName = true;
                        istrue = true;
                      }
                      if (!userdetails.teamName) {
                        x.teamName = true;
                        istrue = true;
                      }
                      if (!fileUpload.bluePrint) {
                        x.filePath.bluePrint = true;
                        istrue = true;
                        setResponse(`Please Upload the bluePrint File`);
                        return
                      }
                      if (!fileUpload.architecture) {
                        x.filePath.architecture = true;
                        istrue = true;
                        setResponse(`Please Upload the Architecture File`);
                        return
                      }
                      if(userdetails.teamDetails.length>=0){
                        let emailList = [];
                        let mobList = [];
                        userdetails.teamDetails.map((itinerary, i) => {
                          if (!itinerary.name) {
                            x.teamDetails[i]["name"] = true;
                            istrue = true;
                          }
                          if (!itinerary.professionName) {
                            x.teamDetails[i]["professionName"] = true;
                            istrue = true;
                          }
                          if (!itinerary.email) {
                            x.teamDetails[i]["email"] = true;
                            istrue = true;
                          }
                          if (!isValidEmail(itinerary.email)) {
                            x.teamDetails[i]["email"] = true;
                            istrue = true;
                          }
                          // if(userdetails.email === itinerary.email){

                          // }
  
                          // if (itinerary.mobileNumber.length > 2) {
                          //   const phoneNumberObj = parsePhoneNumberFromString(`+${itinerary.mobileNumber}`);
                          // if(!phoneNumberObj.isValid()){
                          //   x.teamDetails[i]["mobileNumber"] = true;
                          //   istrue = true;
                          // }
                          // }
                          if (!emailList.includes(itinerary.email))
                            emailList.push(itinerary.email);
                          if (!mobList.includes(itinerary.mobileNumber))
                            mobList.push(itinerary.mobileNumber)

                          if (
                            itinerary.mobileNumber.length > 3
                          ) {
                            const phoneNumberObj = parsePhoneNumberFromString(`+${itinerary.mobileNumber}`);
                            if(!phoneNumberObj.isValid() === true){
                              x.teamDetails[i]["mobileNumber"]= true;
                              istrue = true;
    
                          }
                          }else{
                            x.teamDetails[i]["mobileNumber"] = true;
                            istrue = true;
                          }
                        });

                        if (emailList.length !== userdetails.teamSize) {
                          setResponse("Avoid Email Duplication")
                          return
                        }
                        if (mobList.length !== userdetails.teamSize) {
                          setResponse("Avoid Mobile Number Duplication")
                          return
                        }
                      }

                      setCheck({ ...x });
                      try {
                        const formdata = new FormData();
                        formdata.append(
                          "userDetails",
                          JSON.stringify(userdetails)
                        );
                        formdata.append("file1", fileUpload.bluePrint.blobFile);
                        formdata.append(
                          "file2",
                          fileUpload.architecture.blobFile
                        );

                        if (!istrue) {
                          setProgressBar(true);
                          await axios
                            .postForm(
                              `${appConfig.api}/register-team`,
                              formdata
                            )
                            .then((response) => {
                              if (response.status === 200) {
                                setProgressBar(false);
                                setSuccess(true);
                                handleClose();
                              }
                            })
                            .catch((e) => {
                              setProgressBar(false);
                              setSuccess(false);
                              setResponse(
                                ` ${e.response.data.error
                                  ? ""
                                  : "This information is already available in the system. Please change"
                                } ${e.response.data.error
                                  ? e.response.data.error.message
                                  : e.response.data.message
                                }`
                              );
                            });
                        }
                      } catch (error) {
                        // setResponse(error);
                        // setProgressBar(false);
                      }
                    }}
                    disabled={progressBar ? true : false}
                  >
                    {progressBar ? (
                      <CircularProgress
                        style={{
                          justifyContent: "center",
                          justifyItems: "center",
                          borderColor: "#052a58",
                          width: "20%",
                          height: "20%",
                        }}
                      ></CircularProgress>
                    ) : (
                      <div></div>
                    )}
                    {!progressBar ? "Submit" : ""}
                  </motion.button>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </BootstrapDialog>

      <BootstrapDialog
        onClose={updateHandleClosed}
        aria-labelledby="customized-dialog-title"
        open={updateOpen}
      >
        <Dialog fullWidth maxWidth="md" open={true}>
          <DialogContent>
            <AnimatedModal
              open={response !== ""}
              message={response}
              setResponse={setResponse}
            ></AnimatedModal>
            <Grid container>
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Typography
                    style={{ color: " rgb(0, 7, 61)", margin: "auto" }}
                    variant="h5"
                  >
                    Update Details
                  </Typography>
                  <IconButton
                    aria-label="close"
                    onClick={updateHandleClosed}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid
                  container
                  direction="column"
                  className={classes.mainContent}
                  spacing={1}
                >
                  <Grid container direction="row" spacing={1}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        id="outlined-required"
                        label="Email"
                        size="small"
                        inputProps={{
                          style: {
                            fontFamily: "nunito",
                            color: "rgb(0, 7, 61)",
                            borderColor: "3px solid red",
                          },
                        }}
                        fullWidth
                        variant="outlined"
                        value={updateUserDetails.email}
                        error={updateCheck.email}
                        onChange={(e) => {
                          if (updateCheck.email) {
                            updateCheck.email = false;
                            setUpdateCheck({ ...check });
                          }
                          let detaill = updateUserDetails;
                          detaill.email = e.target.value;
                          setupdateUserDetails({ ...detaill });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {/* <TextField
                        required
                        inputProps={{
                          style: {
                            fontFamily: "nunito",
                            color: "rgb(0, 7, 61)",
                            borderColor: "3px solid red",
                          },
                        }}
                        id="outlined-required"
                        label="Mobile"
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 10);
                        }}
                        size="small"
                        fullWidth
                        variant="outlined"
                        value={updateUserDetails.name}
                        error={updateCheck.name}
                        onChange={(e) => {
                          if (updateCheck.name) {
                            check.name = false;
                            setUpdateCheck({ ...check });
                          }
                          console.log("e.target.value)", e.target.value);
                          if (!isNaN(e.target.value)) {
                            let detaill = updateUserDetails;
                            detaill.name = e.target.value;
                            setupdateUserDetails({ ...detaill });
                          } else {
                            let detaill = updateUserDetails;
                            detaill.name = "";
                            setupdateUserDetails({ ...detaill });
                          }
                        }}
                      /> */}
                      <PhoneInput
                        inputProps={{
                          style: {
                            fontFamily: "nunito",
                            color: "rgb(0, 7, 61)",
                            borderColor: "3px solid red",
                            width: "100%",
                            height: "41px",
                          },
                        }}
                        country={"in"}
                        id="outlined-required"
                        label="Mobile"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={updateUserDetails.name}
                        error={updateCheck.name}
                        isValid={(value, country) => {
                          if (updateCheck.name) {
                            return 'Invalid value number ';
                          }

                          if (value.match(/12345/)) {
                            console.log("false", value, country.format.length)


                          } else if (value.match(/1234/)) {
                            console.log("false")
                            return false;
                          } else {
                            console.log("true")

                            return true;
                          }

                        }}
                        onChange={(value, country, e) => {
                          if (updateCheck.name) {
                            check.name = false;
                            setUpdateCheck({ ...check });
                          }
                          let detaill = updateUserDetails;
                          detaill.name = value;
                          setupdateUserDetails({ ...detaill });

                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <Box>
                      <Typography
                        style={{
                          color: " rgb(0, 7, 61)",
                          marginTop: "10px",
                          marginLeft: 40,
                        }}
                      >
                        BluePrint File
                        {alertOpen && (
                          <Message style={{ fontSize: '10px', width: "90%" }} showIcon type='info' closable onClose={handleAlertClose}>
                            A blueprint is a detailed plan outlining the project's purpose, scope, technologies, timelines, user flows, data flows, challenges, and success metrics,
                            <br></br>
                            Note: Allowed file formats doc,pdf,zip,rar,ppt

                          </Message>
                        )}
                      </Typography>
                      <Uploader
                        draggable
                        defaultFileList={[]}
                        accept=".doc,.docx,.pdf,.zip,.ppt,.pptx,.rar"
                        removable={true}
                        onChange={handleuploadfile}
                        multiple={true}
                        autoUpload={false}
                        style={{
                          marginTop: "0.3rem",
                          marginLeft: 40,
                          marginRight: 40,
                        }}
                      >
                        <div
                          style={{
                            height: 150,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "2px dotted gray",
                          }}
                        >
                          <span>Click or Drag files to upload</span>
                        </div>
                      </Uploader>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box>
                      <Typography
                        style={{
                          color: " rgb(0, 7, 61)",
                          marginTop: "10px",
                          marginLeft: 40,
                        }}
                      >
                        Architecture File
                        <Button style={{ width: '25px', height: '25px', marginLeft: '4px', float: "right" }} onClick={() => handleAlertOpen('blueprint')

                        }><img src={`info-file.png`}></img></Button>
                        {alertOpen && (
                          <Message style={{ fontSize: '10px', width: "90%" }} showIcon type='info' closable onClose={handleAlertClose}>
                            Architecture is the visual representation of a system's structure and interactions between components, helping understand the system's design and communication.
                            <br></br>
                            Note: Allowed file formats doc,pdf,zip,rar,ppt

                          </Message>
                        )}
                      </Typography>
                      <Uploader
                        draggable
                        defaultFileList={[]}
                        onChange={handleUploadFileArchitecture}
                        autoUpload={false}
                        style={{
                          marginTop: "0.3rem",
                          marginLeft: 40,
                          marginRight: 40,
                        }}
                        multiple={false}
                      >
                        <div
                          style={{
                            height: 150,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "2px dotted gray",
                          }}
                        >
                          <span>Click or Drag files to upload</span>
                        </div>
                      </Uploader>
                    </Box>
                  </Grid>
                  <Message style={{ fontSize: '11px', width: "90%", marginLeft: '5%' }} showIcon type='info' closable={false} >
                    <p >
                      For further queries, please reach out to <span>
                        <a style={{ marginLeft: "0px", color: "#052a58", cursor: "pointer" }} href="mailto:hackathon@solutionec.com" target="_blank">

                          hackathon@solutionec.com
                        </a>
                      </span>
                    </p>
                  </Message>
                </Grid>
                <Box textAlign="center" marginTop="10px">
                  <motion.button
                    style={{
                      padding: "10px",
                      cursor: "pointer",
                      justifyContent: "center",
                      background: "#052a58",
                      color: "white",
                      borderRadius: 5,
                      borderColor: "#052a58",
                      width: "25%",
                      // height: "20%",
                      marginTop: 10,
                    }}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9, x: "-5px", y: "5px" }}
                    variant="outlined"
                    onClick={async () => {
                      let x = updateCheck;
                      let istrue = false;
                      if (!updateUserDetails.email) {
                        x.email = true;
                        istrue = true;
                      }


                      if (
                        updateUserDetails.name.length > 3
                      ) {
                        const phoneNumberObj = parsePhoneNumberFromString(`+${updateUserDetails.name}`);
                        if (!phoneNumberObj.isValid() === true) {
                          x.name = true;
                          istrue = true;

                        }
                      } else {
                        x.name = true;
                        istrue = true;
                      }

                      if (!fileUpload.bluePrint && !fileUpload.architecture) {
                        x.filePath.bluePrint = true;
                        istrue = true;
                        setResponse(`Please Upload the File to Update`);
                      }

                      setUpdateCheck({ ...x });
                      try {
                        const formData = new FormData();
                        formData.append("file1", fileUpload.bluePrint.blobFile);
                        formData.append(
                          "file2",
                          fileUpload.architecture.blobFile
                        );
                        if (!istrue) {
                          setProgressBar(true);
                          await axios
                            .postForm(
                              `${appConfig.api}/update-team-details`,
                              formData,
                              {
                                headers: {
                                  mobile: updateUserDetails.name,
                                  email: updateUserDetails.email,
                                },
                              }
                            )
                            .then((response) => {
                              if (response.status === 200) {

                                setProgressBar(false);
                                setSuccess(true);

                                updateHandleClosed();
                              }
                            })
                            .catch((e) => {
                              setProgressBar(false);
                              setSuccess(false);
                              setResponse(
                                ` ${e.response.data.error ? "" : ""} ${e.response.data.error
                                  ? e.response.data.error.message
                                  : e.response.data.message
                                }`
                              );
                            });
                        }
                      } catch (error) {
                        setProgressBar(false);
                        setSuccess(false);
                      }
                    }}
                  >
                    {progressBar ? (
                      <CircularProgress
                        style={{
                          justifyContent: "center",
                          justifyItems: "center",
                          borderColor: "#052a58",
                          width: "20%",
                          height: "20%",
                        }}
                      ></CircularProgress>
                    ) : (
                      <div></div>
                    )}
                    {!progressBar ? "Submit" : ""}
                  </motion.button>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </BootstrapDialog>
    </div>
  );
}

export default withStyles(styles)(Topbar);
