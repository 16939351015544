import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Prizes = (children) => {
  let { toggle } = children;
  useEffect(() => {
    toggle();
  }, []);
  const PrizeList = [
    {
      Category: "1",
      superScript: "st",
      CollegeStudents: "3,00,000",
      Experienced: "3,00,000",
    },

    {
      Category: "2",
      superScript: "nd",
      CollegeStudents: "2,00,000",
      Experienced: "2,00,000",
    },

    {
      Category: "3",
      superScript: "rd",
      CollegeStudents: "1,00,000",
      Experienced: "1,00,000",
    },
  ];

  const BenefitsList = [
    {
      Winning:
        "Mentorship opportunities to take the ideas forward, opportunities to interact with senior business stakeholders and subject matter experts",
      Students: "Yes",
      WorkingProfessional: "Yes",
    },

    {
      Winning: "Internship opportunities for students",
      Students: "Yes",
      WorkingProfessional: "-",
    },

    {
      Winning: "Collaboration opportunities with Solutionec",
      Students: "-",
      WorkingProfessional: "Yes",
    },
    {
      Winning: "A chance to receive initial funding to develop your idea and be a part of profit sharing subjected to Solutionec's criteria and regulations",
      Students: "Yes",
      WorkingProfessional: "Yes",
    },
    ];

  const renderHeaderBenefits = () => {
    let headerElement = ["Winning", "Students", "Working Professionals"];

    return headerElement.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };

  const renderHeader = () => {
    let headerElement = ["Category", "Students"];

    return headerElement.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };
  const renderHeaderExperienced = () => {
    let headerElement = ["Category", "Working Professionals"];

    return headerElement.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };

  const renderBodyBenefits = () => {
    return (
      BenefitsList &&
      BenefitsList.map(({ Winning, Students, WorkingProfessional }) => {
        return (
          <tr key={Winning}>
            <td style={{ textAlign: "left" }}>{Winning}</td>
            <td style={{ textAlign: "center" }}>{Students}</td>
            <td style={{ textAlign: "center" }}>{WorkingProfessional}</td>
          </tr>
        );
      })
    );
  };

  const renderBody = () => {
    return (
      PrizeList &&
      PrizeList.map(({ Category, superScript, CollegeStudents }) => {
        return (
          <tr key={Category}>
            <td style={{ textAlign: "center" }}>
              {Category}
              <sup>{superScript}</sup> Prize
            </td>
            <td style={{ textAlign: "center" }}>{CollegeStudents}</td>
          </tr>
        );
      })
    );
  };
  const renderBodyExperienced = () => {
    return (
      PrizeList &&
      PrizeList.map(({ Category, superScript, Experienced }) => {
        return (
          <tr key={Category}>
            <td style={{ textAlign: "center" }}>
              {Category}
              <sup>{superScript}</sup> Prize
            </td>
            <td style={{ textAlign: "center" }}>{Experienced}</td>
          </tr>
        );
      })
    );
  };
  return (
    <div style={{ marginBottom: "50px", marginLeft: "1rem",marginRight:'1rem' }}>
      <Grid container spacing={2} style={{ paddingRight: "30px" }}>
        <Grid item xs={12} md={12}>
          <Box display="flex">
            <p
              style={{
                fontSize: "15px",
                marginLeft: "30px",
                color: "rgb(0, 7, 61)",
                fontWeight: 800,
                marginTop: "30px",
                marginRight: "10px",
              }}
            >
              What's in it for you
            </p>
            <Divider
              orientation="horizontal"
              thiccness={1}
              color="black"
              sx={{
                borderBottomWidth: "1px",
                color: "black",
                width: "30%",
                height: "0.1px",
                marginTop: "40px",
              }}
            ></Divider>
            <FontAwesomeIcon
              style={{
                marginLeft: "10px",
                marginTop: "30px",
              }}
              icon={faStar}
              color="#f4901f"
            />

            <p
              style={{
                fontSize: "15px",
                marginLeft: "10px",
                color: "rgb(0, 7, 61)",
                fontWeight: 800,
                marginTop: "30px",
                marginRight: "10px",
              }}
            >
              INR 12,00,000 IN PRIZES
            </p>
          </Box>
        </Grid>

        {/* <Grid container direction="row" spacing={1} className="timelineimage">
          <Grid item xs={12} md={12}>
            <p className="main-title">Additional Benefits include:</p>

            <table id="prizeMain">
              <thead>
                <tr>{renderHeaderBenefits()}</tr>
              </thead>
              <tbody>{renderBodyBenefits()}</tbody>
            </table>
          </Grid>
        </Grid>  */}
      </Grid>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} md={12}>
            <p className="main-title" style={{marginTop:"5px"}}>What's in it for you</p>

            </Grid> */}
        <Grid item xs={12} md={12} marginTop={2}>
          <p
            //  className="bullet-points"
            style={{
              fontSize: "16px",
              marginLeft: "30px",
              color: "rgb(0, 7, 61)",
              fontWeight: 500,
              marginTop: "1px",
              marginRight: "1px",
            }}
          >
            Take part and win up to INR 12,00,000 in prizes and more.
          </p>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        spacing={1}
      >
        <Grid item xs={12} md={6}>
          <p className="main-title">Rewards for Students </p>
          <table id="prizeMain">
            <thead>
              <tr>{renderHeader()}</tr>
            </thead>
            <tbody>{renderBody()}</tbody>
          </table>
        </Grid>
        <Grid item xs={12} md={6}>
          <p className="main-title">Rewards for Working Professionals</p>

          <table id="prizeMain">
            <thead>
              <tr>{renderHeaderExperienced()}</tr>
            </thead>
            <tbody>{renderBodyExperienced()}</tbody>
          </table>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={1} >
        <Grid item xs={12} md={12}>
          <p className="main-title">Additional Benefits include:</p>

          <table id="prize">
            <thead>
              <tr>{renderHeaderBenefits()}</tr>
            </thead>
            <tbody>{renderBodyBenefits()}</tbody>
          </table>
        </Grid>
      </Grid>
      {/* </Grid> */}
      {/* <div
       className="prizescard"
      >
        <div>
       
          <p className="main-title">Rewards for Student</p>
          <table id="prizeMain">
            <thead>
              <tr>{renderHeader()}</tr>
            </thead>
            <tbody>{renderBody()}</tbody>
          </table>
         
        </div>
       
        <div>
          <p className="main-title">Rewards for Working Professional</p>
          <table id="prizeMain">
            <thead>
              <tr>{renderHeaderExperienced()}</tr>
            </thead>
            <tbody>{renderBodyExperienced()}</tbody>
          </table>
        </div>
       
      </div>
      <div >
      <Grid container direction="row" spacing={1} >
     
     <Grid item xs={12} md={12} style={{width:"90%"}}>
        <p className="main-title">Additional Benefits include:</p>
        <table id="prizeMain">
          <thead>
            <tr>{renderHeaderBenefits()}</tr>
          </thead>
          <tbody>{renderBodyBenefits()}</tbody>
        </table>
        </Grid>
        </Grid>
      </div> */}
    </div>
  );
};

export default Prizes;
