import {
  Button,
  Card,
  CardActions,
  CardContent,
  Switch,
  Grid,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { Fragment, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import ShareIcon from "@material-ui/icons/Share";
// import FavoriteIcon from "@material-ui/icons/Favorite";
import { motion, Animaepresence } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  card: {
    marginRight: "20px",
    marginLeft: "20px",
    padding: "20px",
  },

  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    width: 90,
  },
  root: {
    display: "flex",
    justifyContent: "center",

    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  list: {
    width: 200,
  },
}));

const FAQList = [
  {
    qutions: "Do I need to pay any money to register for the Hackathon?",
    Answers:
      "No. You do not have to pay anything to anyone to register yourself for the Hackathon.",
  },

  {
    qutions:
      "Do I need to have any specific qualifications to be a participant in the Hackathon?",
    Answers:
      "If you love to code and meet the eligibility criteria, you are more than welcome to participate in the Hackathon.",
  },

  {
    qutions: "How do I submit what I have made for the Hackathon?",
    Answers:
      "You must develop an idea or the application on your local system and submit it on the Solutionec Portal in tar/zip file format along with instructions to run the application and source code.",
  },
  {
    qutions: "Do we need to have the entire idea fully working",
    Answers:
      "The entire idea need not be fully implemented however, the submission should be functional so that it can be reviewed by the judges.",
  },
  {
    qutions:
      "Will your environment support any language? Will you provide any IDE and DB for us to work on ideas? ",
    Answers:
      "You must develop the entire software application on your local system and submit it on the Solutionec Portal in tar/zip file format along with instructions to run the application and source code.",
  },
  {
    qutions:
      "Does one have to be online and available for the entire duration of the Hackathon?",
    Answers:
      "No, the participant does not need to be logged in or be online for the entire duration. You can develop the idea or application on your local system based on the given theme and then submit it on the Solutionec Portal.",
  },
  {
    qutions:
      "Since there is no specific technology mentioned, are there any restrictions on using number of pre-built libraries?",
    Answers:
      "There is no restriction to use any language, technology stack, or libraries. You can use any of them to come up with a working hack.",
  },
  {
    qutions: "Do I need to give a demo for the product that I have built?",
    Answers:
      "If required, participants can submit a small presentation or video that demos their submission, however, it's not mandatory, and only good to have. In case you are one of the winners, you might be invited to demo your application at a physical event, details of which will be shared with sufficient advance notice.",
  },
  {
    qutions:
      "A fully developed application needs to have a solid backend for data storage like SQL, SharePoint etc. since its online, is it accepted to show only the prototype of it?",
    Answers:
      "Yes, it's fine to submit just the prototype. If you use any database like MySQL or PostgreSQL, you can also submit a database dump along with your submission. However, you are allowed to submit just the prototype.",
  },
  {
    qutions:
      "If it is a team submission, does that mean all team members will have access to work at the same time?",
    Answers:
      "Yes, all team members can work together and do application submissions on the Solutionec Portal by mentioning the team name and email address.",
  },
  {
    qutions:
      "Who will own the IP (Intellectual Property) Rights to the product that I have built?",
    Answers:
      "If Solutionec finds your contribution, hack, or code to be extraordinary or of high significance/relevance, it's possible that they may express interest in acquiring the intellectual property (IP) rights associated with it. In such situations, it is crucial for both parties (you, as the creator, and Solutionec) to establish clear and legally binding agreements to protect the interests of all involved including profit sharing with the creator. ",
  },
  {
    qutions: "Do I have to login every time to submit a hack? ",
    Answers:
      "No, you will not be asked to log in every time. You can mention the team’s name and submit it again. ",
  },
  {
    qutions: "Can a team submit hacks on multiple topics? ",
    Answers: "No, one team can submit a hack on one topic only.  ",
  },
  {
    qutions: "Can students be a part of the experienced team and vice-versa? ",
    Answers:
      "No, a team must consist of all experienced or all student participants only. ",
  },
];
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
const FAQs = (children) => {
  let { toggle } = children;
  useEffect(() => {
    toggle();
  }, []);
  const [expanded, setExpanded] = React.useState(-1);
  const classes = useStyles();

  const handleExpandClick = (i) => {
    setExpanded(expanded === i ? -1 : i);
  };

  return (
    <Box
      style={{ marginLeft: "1rem", marginBottom: "100px",marginRight:'1rem' }}
    >
      <p className="main-title">FAQ</p>
      <div
        class="csscolumn"
        style={{ gap: "3%", marginLeft: "30px", marginTop: "30px" }}
      >
        {FAQList.map((itinerary, i) => (
          <Grid
            item
            key={itinerary._id}
            initial={{ scale: 0 }}
            animate={{ scale: 1, transition: { delay: 0.5, type: "spring" } }}
            whileHover={{ scale: 0.5 }}
            transition={{
              opacity: { ease: "linear" },
              layout: { duration: 0.3 },
            }} // whileTap={{ scale: 0.9, x: "0px", y: "0px" }}
          >
            <Card gap={10} variant="outlined" key={itinerary._id}>
              <CardContent sx={{ padding: "0px" }} />
              <CardActions className="faq" disable>
                <Typography
                  justifyContent="center"
                  style={{
                    color: "white",
                    display: "flex",
                    fontFamily: "Arial, Verdana, sans-serif",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div class="second-half">{`${++i}. `}</div>
                    <div class="second-half">{`${itinerary.qutions}`}</div>
                  </div>
                </Typography>
                <IconButton
                  onClick={() => handleExpandClick(i)}
                  // aria-expanded={expanded === i}
                  aria-label="show more"
                >
                  <ExpandMoreIcon style={{ color: "white" }}></ExpandMoreIcon>
                </IconButton>
              </CardActions>
              <Collapse in={expanded === i} timeout="auto" unmountOnExit>
                <CardContent
                  style={{
                    fontFamily: "Arial, Verdana, sans-serif",
                    justifyContent: "space-between",
                    background: "#052a58",
                  }}
                  sx={{ padding: "0px" }}
                >
                  <Typography
                    style={{
                      marginLeft: "15px",
                      marginRight: "3rem",
                      verticalAlign: "middle",
                     textAlign:"justify",
                      color: "white",
                    }}
                  >
                    {itinerary.Answers}
                  </Typography>
                </CardContent>
              </Collapse>
            </Card>
          </Grid>
        ))}
       

      </div>
     
      <p className="hackathon-Note" style={{marginTop:"30px",textAlign:"justify"}}>
      If you have any queries related to the hackathon, please reach out to <span>
      <a style={{marginLeft:"0px",color:"#052a58",cursor:"pointer"}} href="mailto:hackathon@solutionec.com"target="_blank">
      
       hackathon@solutionec.com
              </a>
              </span>
      </p>
   
    </Box>
  );
};

export default FAQs;
