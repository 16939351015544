import React, { Children, useEffect } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router";

const Rules = (children) => {
  let { toggle } = children;
  const navigate = useNavigate();
  const navigateToContacts = () => {
    // 👇️ navigate to /contacts
    navigate("/Themes");
  };
  const RulesList = [
    {
      Rules:
        "If Solutionec finds your contribution, hack, or code to be extraordinary or of high significance/relevance, it's possible that they may express interest in acquiring the intellectual property (IP) rights associated with it. In such situations, it is crucial for both parties (you, as the creator, and Solutionec) to establish clear and legally binding agreements to protect the interests of all involved including profit sharing with the creator.",
      hyperlink: "",
      hyperlinktext: "",
    },
    {
      Rules:
        "This is an individual/team participation hackathon. You can have at most 5 members in your team.",
      hyperlink: "",
      hyperlinktext: "",
    },
    {
      Rules:
        "Individuals matching the eligibility criteria can enter the competition.",
      hyperlink: "",
      hyperlinktext: "",
    },
    {
      Rules:
        "The hackathon will start on 25/07/2023 at 10 AM IST and end on at 29/08/2023 6 PM IST, you can work on your hack and submit your ideas within the allotted time only.",
      hyperlink: "",
      hyperlinktext: "",
    },
    {
      Rules: "It's an online hackathon, you can participate from anywhere.",
      hyperlink: "",
      hyperlinktext: "",
    },
    {
      Rules: `Participants must submit a hack that is related to the mentioned `,
      hyperlink: "",
      hyperlinktext: "",
    },
    {
      Rules:
        "Once the hackathon starts, you will get an option to submit your hack, you can submit as many times as you want, and the last hack will be considered as the final submission.",
      hyperlink: "",
      hyperlinktext: "",
    },
    // {
    //   Rules: "The submission files should be named in ",
    //   hyperlink: "",
    //   hyperlinktext: "TeamName_Date of submission (DD_MM_YY)_BluePrint or TeamName_Date of submission (DD_MM_YY)_Architecture",
    // },
    {
      Rules:
        "You are expected to come up with new and innovative ideas, any idea that has been copied or plagiarised will be disqualified.",
      hyperlink: "",
      hyperlinktext: "",
    },
    {
      Rules:
        "There will be no travel and stay reimbursement for the onsite hackathon.",
      hyperlink: "",
      hyperlinktext: "",
    },
    {
      Rules: "The location for the Onsite Hackathon is ",
      hyperlink:
        "https://www.google.com/maps/place/Solutionec+Private+Ltd/@12.9928072,77.7007124,17z/data=!3m1!4b1!4m6!3m5!1s0x3bae11cc59d758b3:0x3ad3ff19fe248250!8m2!3d12.992802!4d77.7032873!16s%2Fg%2F11khd_vzs8?entry=ttu",
      hyperlinktext: " Solutionec Bangalore.",
    },
    {
      Rules:
        "Your hack must be developed entirely during the Hackathon duration. You may use open-source libraries and other freely available systems/services.",
      hyperlink: "",
      hyperlinktext: "",
    },
    {
      Rules:
        "By participating in the hackathon, you agree to the terms and conditions of Solutionec.",
      hyperlink: "",
      hyperlinktext: "",
    },
  ];
  useEffect(() => {
    toggle();
  }, []);
  return (
    <Box style={{ marginBottom: "50px", marginLeft: "1rem",marginRight:'1rem' }}>
      <p className="main-title">RULES</p>
      <Box>
        {RulesList.map((itinerary, i) => (
          <div>
            {i == 5 ? (
              <ul className="main-titletheams"  style={{ textAlign:"justify"}}>
                {" "}
                <li>
                  {" "}
                  {itinerary.Rules}
                  <span
                    style={{
                      fontFamily: "Arial, Verdana, sans-serif",
                      fontWeight: "bold",
                      color: "#052a58",
                      cursor: "pointer",
                    }}
                    onClick={navigateToContacts}
                  >
                    theme
                  </span>{" "}
                  and requirements.
                </li>
              </ul>
            ) : (
              <ul className="main-titletheams" style={{ textAlign:"justify"}}>
                {" "}
                <li>
                  {" "}
                  {itinerary.Rules}
                  <a
                    className="sub-title"
                    style={{
                      fontFamily: "Arial, Verdana, sans-serif",
                      marginLeft: "1px",
                      color: "#052a58",
                      textAlign:"justify"
                    }}
                    href={itinerary.hyperlink}
                    target="_blank"
                  >
                    {itinerary.hyperlinktext}
                  </a>
                </li>
              </ul>
            )}
          </div>
        ))}
      </Box>
    </Box>
  );
};

export default Rules;
