import { Box, Grid } from "@mui/material";
import React from "react";
import { Route, Routes, useNavigate } from "react-router";
import Themes from "./Themes";

const TimeLine = (children) => {
  let { toggle } = children;
  const navigate = useNavigate();
  const navigateToContacts = () => {
    // 👇️ navigate to /contacts
    navigate("/Themes");
  };
  return (
    <div
      style={{ marginBottom: "50px", marginLeft: "1rem",marginRight:'1rem'}}
    >
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12} md={12}>
          <p className="main-title" style={{ marginTop: "40px" }}>
            Registration involves two steps:
          </p>
          <ul className="main-titletheams" style={{  marginTop: "20px" }}>
            {" "}
            <li> Register yourself individually or as a team. </li>
          </ul>
          <ul className="main-titletheams">
            {" "}
            <li>
              {" "}
              Idea Submission (from July 25th to August 29th) is only possible
              after you have registered and formed a team. You need to submit a
              hack or an idea based on the given theme.{" "}
              <span style={{ fontWeight: "bold",color:"#052a58",cursor:"pointer" }} onClick={navigateToContacts}>
                Click here{" "}
              </span>
              for themes.{" "}
            </li>
          </ul>

          <ul className="main-titletheams">
            {" "}
            <li>
              {" "}
              Announcement of shortlisted teams (September 30th) for the
              in-person hackathon event in Bangalore. Kindly note, teams need to
              organize their travel and stay as mentioned in the Rules section.{" "}
            </li>
          </ul>

          <ul className="main-titletheams">
            {" "}
            <li>
              {" "}
              In-person hackathon (October) and finale will be held at the{" "}
              <a  className="sub-title"  style={{marginLeft:"1px",color:"#052a58",cursor:"pointer"}}href="https://www.google.com/maps/place/Solutionec+Private+Ltd/@12.9928072,77.7007124,17z/data=!3m1!4b1!4m6!3m5!1s0x3bae11cc59d758b3:0x3ad3ff19fe248250!8m2!3d12.992802!4d77.7032873!16s%2Fg%2F11khd_vzs8?entry=ttu"target="_blank">
                Solutionec Office, Bangalore.
              </a>{" "}
            </li>
          </ul>
          <p className="main-title">Important Dates:</p>

          <ul className="main-titletheams">
            {" "}
            <li> 25th July &ndash; Start of Hackathon.</li>
          </ul>

          <ul className="main-titletheams">
            {" "}
            <li> 29th August &ndash; Last date of submit ideas.</li>
          </ul>
          <ul className="main-titletheams">
            {" "}
            <li> 30th September &ndash; Shortlist Announcement</li>
          </ul>

          <ul className="main-titletheams">
            {" "}
            <li>
              {" "}
              October : In-person hackathon (Dates will be announced prior to
              the event)
            </li>
          </ul>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <p className="main-title" style={{ marginTop: "40px" }}>
            Timelines
          </p>

          <img
            style={{
              width: "90%",
              height: "80%",
              marginLeft: "30px",
              marginTop: "20px",
            }}
            src={`timeLine banner.png`}
            alt={""}
            loading="lazy"
          />
        </Grid> */}
      </Grid>
    </div>
  );
};

export default TimeLine;
